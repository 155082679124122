import { Box, SimpleGrid } from "@chakra-ui/react";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import DestinationCard from "../components/Collection-flow/DestinationCard";

import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AppContext } from "../context/AppContext";

const destinations = [
    {
        imageSrc: "/images/iceland.jpg",
        headingText: "Iceland",
        subheadingText: "Northern lights spots"
    },
    {
        imageSrc: "/images/iceland.jpg",
        headingText: "Europe",
        subheadingText: "Southern"
    },
    {
        imageSrc: "/images/iceland.jpg",
        headingText: "South Asia",
        subheadingText: "memorable"
    },
    {
        imageSrc: "/images/iceland.jpg",
        headingText: "Night Life",
        subheadingText: "Best of"
    },
    {
        imageSrc: "/images/iceland.jpg",
        headingText: "Switzerland",
        subheadingText: "Enchanting"
    },
    {
        imageSrc: "/images/iceland.jpg",
        headingText: "Japan",
        subheadingText: "Best of"
    }
];

const Collection: React.FC = () => {
    const navigate = useNavigate();
    const context: any = useContext(AppContext);
    const { setIsCollection } = context;
    return (
        <Box
            minH="100vh"
            w="100vw"
            overflow="hidden"
        >
            <Box
                position="relative"
                zIndex={1}
                px="12px">
                <Box mb="40vh">

                    <SimpleGrid columns={2} spacing={3} alignItems={'center'} justifyContent={'center'} >
                        {destinations.map((destination, index) => (
                            <DestinationCard
                                key={index}
                                onClick={() => { 
                                    setIsCollection(true);
                                    navigate('/home/story-carousel');

                                 }}
                                imageSrc={destination.imageSrc}
                                headingText={destination.headingText}
                                subheadingText={destination.subheadingText}
                            />
                        ))}
                    </SimpleGrid>

                </Box>
            </Box>
        </Box>

    );
}

export default Collection;