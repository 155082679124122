import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Text,
  VStack,
  Image
} from '@chakra-ui/react';
import TrPrimaryButton from './TrPrimaryButton';
import { useNavigate } from 'react-router-dom';

interface CustomModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const CustomModal: React.FC<CustomModalProps> = ({ isOpen, onClose }) => {
  const navigate=useNavigate();
  const handleMainButton = () => {
    navigate("/home/navigate?tab=bucketlists");
    onClose();
  }
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      {/* Modal overlay with transparent gray gradient */}
      <ModalOverlay
        bg="#000000A1"
      />
      <ModalContent
        bg="white"
        borderRadius="5%"
        p="2%"
        textAlign="center"
        maxWidth="90%"
        h="auto"
        boxShadow="lg"
      >
        <ModalCloseButton borderRadius="full" bg="#D9D9D9" m="2%" onClick={handleMainButton} />
        <ModalBody>
          <VStack spacing={6} fontFamily="Poppins" color="#000">
            {/* Icon with Circle Background */}
            <Image src="/icons/launching.svg" alt="broadcast" width="20%" />

            {/* Heading */}
            <Text
              fontSize="1.75rem"
              fontStyle="normal"
              fontWeight="700"
              lineHeight="1.75rem"
              letterSpacing="-0.02em"
            >
              Launching Soon!
            </Text>

            {/* Description */}
            <Text
              fontSize="0.875rem"
              fontWeight="400"
              mt="-0.1em"
              lineHeight="1.35"
              textAlign="center"
            >
              Lorem ipsum dolor sit amet consectetur. Volutpat ornare ut congue nisl lacus.
            </Text>

            {/* Button */}
            <TrPrimaryButton
              w="40%"
              mt="1em"
              px="1.2em"
              py="0.8em"
              onClick={handleMainButton}
            >
              Ok, got it
            </TrPrimaryButton>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CustomModal;
