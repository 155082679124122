import { Avatar, Flex, Text } from '@chakra-ui/react';

interface InfluencerrIdProps {
	infId: any;
}

const InfluencerId: React.FC<InfluencerrIdProps> = ({ infId }) => {
	return (
		<Flex justifyContent={'flex-start'} alignItems={'center'} gap={'10px'}>
			<Avatar
				src={infId?.['influencerImage']?infId?.['influencerImage']:'/icons/infProfile.svg'}
				title='DP'
				boxSize={'40px'}
			/>
			{/* <Flex flexDir={'column'}> */}
			<Text
				fontFamily='Poppins'
				fontSize='16px'
				fontWeight='700'
				lineHeight='19px'
				letterSpacing='-0.699999988079071px'
				textAlign='left'
				color={'#FFFFFFFC'}
				mt={-2}>
				{infId?.['channelName']}
			</Text>
			{/* <Text
                    fontFamily="Poppins"
                    fontSize="13px"
                    fontWeight="700"
                    lineHeight="19px"
                    color={'#D9D9D9'}
                    letterSpacing="-0.699999988079071px"
                    textAlign="left"
                >
                    {infId["followers"]}
                    <Text as='span' color={'#878787'}>
                        {" "}
                    </Text>
                </Text> */}
			{/* </Flex> */}
		</Flex>
	);
};

export default InfluencerId;
