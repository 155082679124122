import React from 'react';
import { Flex } from '@chakra-ui/react';
import HotelListItem from './HotelListItem';

interface HotelListProps {
	city: any;
	hotel_booking_link: string;
}

const HotelList: React.FC<HotelListProps> = ({ city, hotel_booking_link }) => {
	return (
		<Flex direction='column'>
			{/* {city} */}

			<>
				<HotelListItem
					title={`Hotels to stay in ${city}`}
					startingPrice={'n/a'}
					link={hotel_booking_link}
				/>

				<HotelListItem
					title={`Explore top things to see in ${city}`}
					startingPrice={'n/a'}
					link={'#'}
				/>

				<HotelListItem
					title={`Explore ${city} tours`}
					startingPrice={'n/a'}
					link={'#'}
				/>
			</>
		</Flex>
	);
};

export default HotelList;
