import { Box, Button, Heading, HStack } from "@chakra-ui/react";


interface HeaderProps {
    backIconSrc?: string;
    chatIconSrc?: string;
    addIconSrc?: string;
    handleBackButton: ()=>void;
    chatButtonOnClick?: () => void;
    addButtonOnClick?: () => void;
    bgColor?: string;
    borderColor?: string;
    heading?:string
}

const Header: React.FC<HeaderProps> = ({
    backIconSrc,
    chatIconSrc,
    addIconSrc,
    handleBackButton,
    chatButtonOnClick,
    addButtonOnClick,
    bgColor,
    borderColor,
    heading
}) => {
    

    return (
        <Box>
            <HStack alignItems={"center"} justifyContent={"space-between"} mx={"12px"} mt={"10px"} py={1} >
                <HStack>
                    {backIconSrc && handleBackButton && (<Button bg='inherit' variant='link' onClick={handleBackButton}>
                        <img src={backIconSrc} alt='back' />
                    </Button>)}
                    {heading&&(<Heading
                        fontFamily="Poppins"
                        fontSize="16px"
                        fontWeight="700"
                        lineHeight="21.28px"
                        letterSpacing="-0.5611507892608643px"
                        textAlign="left"
                        color={'#FFFFFFD4'}
                    >{heading}</Heading>)}
                </HStack>
                <HStack spacing={'14px'}>
                    {addIconSrc && (<Button bg='inherit' variant='link' onClick={addButtonOnClick}>
                        <Box
                            border={`0.76px solid ${borderColor}`}
                            p={"7.5"}
                            borderRadius={"9.03px"}
                            bg={bgColor}
                        >
                            <img src={addIconSrc} alt='add' />
                        </Box>

                    </Button>)}
                    {chatIconSrc && (<Button bg='inherit' variant='link' onClick={chatButtonOnClick}>
                        <Box
                            border={`0.76px solid ${borderColor}`}
                            p={"7.5"}
                            borderRadius={"9.03px"}
                            bg={bgColor}
                        >
                            <img src={chatIconSrc} alt='chat' />
                        </Box>
                    </Button>)}
                </HStack>
            </HStack>
        </Box>
    );
}

export default Header;
