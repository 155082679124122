import React, { useEffect, useState } from 'react';
import '@fontsource/sacramento';
import { Box, Flex, Text, VStack, Button } from '@chakra-ui/react';
import ItineraryCard from './ItineraryCard';
import data from './data.json'; // Ensure this path is correct
import { useParams } from 'react-router-dom';

interface TripDetailProps {
  title: string;
  subtitle: string;
  description: string;
}

const TripDetail: React.FC<TripDetailProps> = ({
  title,
  subtitle,
  description,
}) => {
  const { id } = useParams<{ id: string }>();
  const [itinerary, setItinerary] = useState<any[]>([]);

  useEffect(() => {
    if (id) {
      // Convert ID to number if necessary
      const selectedId = parseInt(id, 10);
      const selectedDestination = data.destinations.find((dest) => dest.id === selectedId);
      if (selectedDestination) {
        setItinerary(selectedDestination.itinerarydetail);
      }
    }
  }, [id]);

  return (
    <Box
      bg="none"
      color="white"
      p={4}
      rounded="lg"
      maxW={["auto", "auto", "inherit"]}
      mx="auto"
    >
      {/* Header Section */}
      <VStack align="start" mb={8}>
        <Flex justifyContent={'space-between'} alignItems={'center'} w={'full'}>
          <Box>
            <Text
              zIndex={2}
              fontFamily={'Sacramento'}
              fontSize={'36px'}
              ml={[0, 0, 12]}
              color={["#FFFFFFD4", "#FFFFFFD4", "#878787"]}
              textTransform={"lowercase"}
            >
              {title}
            </Text>
            <Text
              mt={["-7", "-7", -10]}
              fontSize="57px"
              fontWeight="700"
              bg="linear-gradient(180deg, rgba(255, 255, 255, 0.93) 53.49%, rgba(57, 55, 55, 0.93) 95.69%)"
              bgClip="text">
              {subtitle}
            </Text>
          </Box>
          <Box bg={'#2B2A2C'} px={4} py={3} borderRadius={'99px'} h={'fit-content'} display={['none', 'none', 'flex']}>
            <Button fontSize='13px' fontWeight='600' color='#AE7CFF' variant='link'>
              + Save Itinerary
            </Button>
          </Box>
        </Flex>
        <Text
          fontSize="md"
          color="#FFFFFFA1"
          noOfLines={5}
          textAlign={'justify'}
        >
          {description}
        </Text>
        {itinerary.map((dayItinerary, dayIndex) => (
          <ItineraryCard
            key={dayIndex}
            itineraryData={dayItinerary}
          />
        ))}
      </VStack>
    </Box>
  );
};

export default TripDetail;
