import { HStack, Box, VStack, Divider } from '@chakra-ui/react';

import MoreFromInfluecerCard from './MoreFromInfluecerCard';
import HomePageFooterWeatherCard from './HomePageFooterWeatherCard';
import HomePageFooterFlightCard from './HomePageFooterFlightCard';

const FlightInfo = (props) => {
	const { weatherInfo, travelInfo, places } = props;
	return (
		<HStack>
			<Box
				w={'35%'}
				bg={'white'}
				p={'0.5px'}
				borderRadius={'20px'}
				bgGradient='linear(to-r,#aaa7a7 , #111012)'>
				<VStack width={'100%'} borderRadius={'20px'} bg={'#111012'}>
					<HomePageFooterWeatherCard weatherInfo={weatherInfo} />
					<Divider
						color={'#6d6d6d'}
						borderStyle={'dashed'}
						pl={'8%'}
						w={'80%'}
					/>
					<HomePageFooterFlightCard travelInfo={travelInfo} />
				</VStack>
			</Box>
			<HStack gap={'60px !important'} width={'65%'}>
				{places.map((value, index) => {
					return (
						<MoreFromInfluecerCard
							key={index}
							placeName={value['placeName']}
							imageUrl={value['thumbnailUrl']}
							videoUrl={value['videoUrl']}
							boxSize={'200px'}
							width={'155px'}
						/>
					);
				})}
			</HStack>
		</HStack>
	);
};

export default FlightInfo;
