import React from 'react';
import { Box, Image, Skeleton } from '@chakra-ui/react';
import { motion } from 'framer-motion';

// Define the prop types for ExploreButton
interface ExploreButtonProps {
  handleNextLocation: () => void;
}

// Forward ref for Chakra UI Image component using React's forwardRef
const MotionImage = motion(
  React.forwardRef<HTMLImageElement, any>((props, ref) => (
    <Image ref={ref} {...props} />
  ))
);

// Use ExploreButtonProps as the generic for React.FC
const ExploreButton: React.FC<ExploreButtonProps> = ({ handleNextLocation }) => {
  return (
    <Box position="relative" display="inline-block">
      {/* Rotating circular MotionImage */}
      <MotionImage
        boxSize="94px"
        src="/icons/explore1.svg"
        alt="Explore"
        animate={{
          rotate: [0, 360], // 360 degrees rotation for circular motion
        }}
        transition={{
          repeat: Infinity,
          duration: 5, // Duration of the circular motion
          ease: 'linear',
        }}
        onClick={handleNextLocation} // On click, move to the next location
        cursor="pointer"
      />

      {/* Arrow image centered over the MotionImage */}
      <Image
        src='/icons/arrow_right.svg'
        alt='arrow'
        position="absolute"
        top="50%" // Align vertically in the middle
        left="50%" // Align horizontally in the middle
        transform="translate(-50%, -50%)" // Offset the centering to account for the image size
        pointerEvents="none" // Ensure that the arrow doesn't capture pointer events
        fallback={<Skeleton height="100%" width="100%" />}
      />
    </Box>
  );
};

export default ExploreButton;
