import { Box, VStack, useDisclosure } from "@chakra-ui/react";
import Header from "./Header";
import TabNavigation from "./TabNavigation";
import { useContext, useState, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import useAxios from "../../hooks/useAxios";
import ModalWithBackdrop from "../ModalWithBackdrop";  // Import the modal with backdrop
import SigninComponent from "../SigninComponent";      // Import the signin component
import useAuthRouteProtector from "../../hooks/useAuthRouteProtector";

const LandingPage: React.FC = () => {
    const context: any = useContext(AppContext);
    const { fetchData } = useAxios();
    const { setLikeEntities, setLikeSearches } = context;
    const { isAuthenticated } = useAuthRouteProtector();
    const [once, setOnce] = useState(1)

    // Modal control for the Signin modal
    const { isOpen, onOpen, onClose } = useDisclosure();

    useEffect(() => {
        const getUserLikeSearches = async () => {
            try {
                const { data } = await fetchData({
                    method: "POST",
                    url: `user/getUserLikeSearches`,
                    options: {
                        data: { token: localStorage.getItem("token") },
                        headers: { "Content-Type": "application/json" },
                    },
                    auth: false,
                });

                const like_searches_data = data["data"];
                const like_searches = like_searches_data["like_searches"];
                setLikeSearches(like_searches);
                return like_searches;
            } catch (error) {
                console.error("Error fetching user like searches:", error);
                return [];
            }
        };

        const getUserLikeEntities = async () => {
            try {
                const { data } = await fetchData({
                    method: "POST",
                    url: `user/getUserLikeEntities`,
                    options: {
                        data: { token: localStorage.getItem("token") },
                        headers: { "Content-Type": "application/json" },
                    },
                    auth: false,
                });

                const like_entities_data = data["data"];
                const like_entities = like_entities_data["like_entities"];
                setLikeEntities(like_entities);
                return like_entities;
            } catch (error) {
                console.error("Error fetching user like entities:", error);
                return [];
            }
        };

        // Trigger modal if user is not authenticated (based on token or other conditions)
        if (!localStorage.getItem('token')) {
            
        } else if (localStorage.getItem('token') && once) {
            getUserLikeSearches();
            getUserLikeEntities();
            setOnce(0);
        }

    }, [fetchData, setLikeEntities, setLikeSearches, once, onOpen]);

    return (
        <>
            {/* Signin Modal */}
            <ModalWithBackdrop isOpen={isOpen} onClose={onClose}>
                <SigninComponent onClose={onClose} />
            </ModalWithBackdrop>

            {/* Main page content */}
            <VStack bg="black" h={"100%"} w={"100%"} overflowX={'hidden'} overflowY={'auto'} minH={'100dvh'}>
                <Box
                    position={'fixed'}
                    top={0}
                    left={0}
                    w={'100vw'}
                    h={'80vh'}
                    zIndex={1}
                    bg={"linear-gradient(180deg, #252128 0%, rgba(36, 29, 50, 0) 100%)"}
                />
                {/* Header: Fixed position at the top */}
                <Box
                    position="fixed"
                    top={0}
                    left={0}
                    w="100vw"
                    zIndex={10}  // Keep the z-index high to be on top
                >
                    <Header onOpenLogin={onOpen} isAuthenticated={isAuthenticated} />
                </Box>

                {/* Main content below the header */}
                <VStack zIndex={5} w="100%" spacing={3} pt="95px"> {/* Adjust padding-top */}
                    {/* Padding added to prevent overlap with fixed Header */}
                    <TabNavigation />
                </VStack>
            </VStack>
        </>

    );
};

export default LandingPage;
