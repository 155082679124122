import {
    VStack,
    Image,
    Heading,
    Box,
    Button,
    IconButton,
} from "@chakra-ui/react";
import Detail from "./Detail";
import { useNavigate } from "react-router-dom";
import useAxios from "../../hooks/useAxios";
import { useEffect, useState } from "react";
import ItineraryFooter from "../CreateItinary/ItineraryFooter";

const ProfileView: React.FC = () => {
    const [email, setEmail] = useState("");
    const [phoneno, setPhoneNo] = useState("");
    const [travelerDetails, setTravelerDetails] = useState("");
    const [editEmail, setEditEmail] = useState(false);
    const [editPhone, setEditPhone] = useState(false);
    const [editTravelerDetails, setEditTravelerDetails] = useState(false);
    const { fetchData } = useAxios();

    useEffect(() => {
        const fetchUserDetail = async () => {
            const { data } = await fetchData({
                method: "GET",
                url: `user/profile`,
                options: {
                    data: {},
                    headers: { "Content-Type": "application/json" },
                },
                auth: true,
            });
            if (data) {
                if (data.email) setEmail(data.email);
                setPhoneNo(data.phone_number);
                setTravelerDetails(data.traveler_details);

            }
        };

        fetchUserDetail();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const saveUserDetail = async () => {
        fetchData({
            method: "PUT",
            url: `user/profile`,
            options: {
                data: {
                    email: email,
                    phone_number: phoneno,
                    traveler_details: travelerDetails,
                },
                headers: { "Content-Type": "application/json" },
            },
            auth: true,
        });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps

    const handleSaveEmail = () => {
        setEditEmail(false);
        saveUserDetail();

        // Perform API call to save the email
    };

    const handleSavePhone = () => {
        setEditPhone(false);
        saveUserDetail();
        // Perform API call to save the phone number
    };

    const handleSaveAboutMe = () => {
        setEditTravelerDetails(false);
        saveUserDetail();
        // Perform API call to save the phone number
    };

    const router = useNavigate();
    return (
        <>
            <Box
                position="fixed"
                top="0"
                left="0"
                width="100vw"
                height="100dvh"
                bgColor="blackAlpha.900"
                zIndex={2} // Ensures it appears above other content
            />
            <Box position="fixed" zIndex={5} top="6vh" left="3vw">
                <IconButton
                    aria-label="Back"
                    icon={<Image src="/icons/Back_Icon_White.svg" alt="back" />}
                    colorScheme="gray"
                    variant="link"
                    size="lg"
                    onClick={() => {
                        router(`/home`);
                    }}
                />
            </Box>
            <VStack
                mt={"30vh"}
                bg={"#242127"}
                spacing={0}
                h={"70vh"}
                zIndex={5}
            >
                <Image
                    src={"/icons/profile2.svg"}
                    alt="user"
                    boxSize="72px"
                    objectFit={"cover"}
                    mt={-37}
                />
                <Heading
                    mt={"25px"}
                    fontFamily="Poppins"
                    fontSize="20px"
                    fontWeight="600"
                    lineHeight="20px"
                    letterSpacing="-0.5px"
                    textAlign="center"
                    mb={"36px"}
                    color={"#FFFFFF"}
                >
                    Traveller Profile
                </Heading>

                <Detail
                    label="Email"
                    value={email}
                    buttonText={editEmail ? "Save" : "Edit"}
                    editMode={editEmail}
                    onEditClick={() =>
                        editEmail ? handleSaveEmail() : setEditEmail(true)
                    }
                    onChange={(e) => setEmail(e.target.value)}
                />
                <Detail
                    label="Phone"
                    value={phoneno}
                    buttonText={editPhone ? "Save" : "Edit"}
                    editMode={editPhone}
                    onEditClick={() =>
                        editPhone ? handleSavePhone() : setEditPhone(true)
                    }
                    onChange={(e) => setPhoneNo(e.target.value)}
                />
                <Detail
                    label="Traveler Details"
                    value={travelerDetails}
                    buttonText={editTravelerDetails ? "Save" : "Edit"}
                    editMode={editTravelerDetails}
                    onEditClick={() =>
                        editTravelerDetails ? handleSaveAboutMe() : setEditTravelerDetails(true)
                    }
                    onChange={(e) => setTravelerDetails(e.target.value)}
                />
                <Button
                    mt={"12%"}
                    w="74px"
                    h="20px"
                    variant="link"
                    fontFamily="Poppins"
                    fontSize="20px"
                    fontWeight="600"
                    lineHeight="20px"
                    letterSpacing="-0.5px"
                    textAlign="center"
                    color="#AE7CFF"
                    onClick={() => {
                        localStorage.removeItem("token");
                        router("/home");
                        window.location.reload();
                    }}
                >
                    Log Out
                </Button>
            </VStack>
            <ItineraryFooter />
        </>
    );
};

export default ProfileView;
