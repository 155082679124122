import { useBreakpointValue } from "@chakra-ui/react";
import LandingPage from "../components/LandingPage/LandingPage";
import HomePageDesktop from "../components/HomeDesktopDesign";

export const SplitDevice: React.FC = () => {
    // Use useBreakpointValue to determine the view based on the screen size
    const isMobileView = useBreakpointValue({ base: true, md: false });

    return (
        <>
            {isMobileView ? (
                <LandingPage />
            ) : (
                <HomePageDesktop />
            )}
        </>
    );
};
