import { VStack, Text, Button, Flex, Input } from "@chakra-ui/react";

interface DetailProps {
  label: string;
  value: string;
  buttonText: string;
  editMode?: boolean;
  onEditClick: () => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Detail: React.FC<DetailProps> = ({ label, value, buttonText, editMode, onEditClick, onChange }) => {
  return (
    <VStack
      borderRadius="12px"
      mx="30px"
      py="18px"
      px="30px"
      w="360px"
      mb={"10px"}
      minH={'73px'}
      border="1px solid #FFFFFF12"
      alignItems="space-between"
      maxW="100vw"
    >
      <Flex justifyContent="space-between" width="100%">
        <Flex flexDir="column" flex="1">
          <Text
            fontFamily="Poppins"
            fontSize="13px"
            fontWeight="500"
            lineHeight="17px"
            letterSpacing="-0.465063214302063px"
            textAlign="left"
            color="#FFFFFF54"
          >
            {label}
          </Text>
          {editMode ? (
            <Input
              value={value}
              onChange={onChange}
              fontFamily="Poppins"
              fontSize="16px"
              fontWeight="500"
              lineHeight="17px"
              letterSpacing="-0.47px"
              textAlign="left"
              color="#FFFFFFED"
              bgColor="transparent"
              border="none"
              borderBottom="1px solid #FFFFFFED"
              _focus={{ outline: "none" }}
            />
          ) : (
            <Text
              fontFamily="Poppins"
              fontSize="16px"
              fontWeight="500"
              lineHeight="17px"
              letterSpacing="-0.47px"
              textAlign="left"
              color="#FFFFFFED"
              maxW={'250px'}
              isTruncated={label !== "Traveler Details"}
              >
              {value}
            </Text>
          )}
        </Flex>
        <Button
          w="23px"
          h="17px"
          variant="link"
          fontFamily="Poppins"
          fontSize="13px"
          fontWeight="500"
          lineHeight="17px"
          letterSpacing="-0.465063214302063px"
          textAlign="left"
          color="#AE7CFF"
          onClick={onEditClick}
        >
          {buttonText}
        </Button>
      </Flex>
    </VStack>
  );
};

export default Detail;
