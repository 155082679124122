import {Button, Image, Text, Flex } from "@chakra-ui/react";

interface ItineraryProps {
    imageSrc: string;
    title: string;
    dates: string;
    numberOfAdults: number;
    onEdit: () => void;
}

const ItineraryCardLayout: React.FC<ItineraryProps> = ({ imageSrc, title, dates, numberOfAdults, onEdit }) => {
    return (
        <Flex flexDir={'column'} w={'90vw'} >
            <Flex alignItems="flex-start">
                <Image
                    src={imageSrc}
                    borderRadius="7.09px"
                    boxSize={'56.03px'}
                    border="1.06px solid #313030"
                    mr={4}
                />
                <Flex flexDir={"column"} gap={2}>
                    <Text
                        color={'#FFFFFFD4'}
                        fontFamily="Poppins"
                        fontSize="20px"
                        fontWeight="700"
                        lineHeight="26.94px"
                        letterSpacing="-0.5611507892608643px"
                        textAlign="left"
                    >{title}</Text>
                    <Text
                        fontFamily="Poppins"
                        fontSize="13px"
                        fontWeight="500"
                        lineHeight="16px"
                        color={'#FFFFFFA1'}

                    >{dates} • {numberOfAdults} {numberOfAdults > 1 ? 'Adults' : 'Adult'}</Text>
                    <Button borderRadius={'99px'} 
                    w={'68px'} 
                    fontWeight={'700'} 
                    bg={'#2B2A2C'} 
                    color={'#AE7CFF'} 
                    size="sm"
                    mt={2} onClick={onEdit}>+ Edit</Button>
                </Flex>
            </Flex>

        </Flex>
    );
};

export default ItineraryCardLayout;