import React from "react";
import {
    Box,
    Text,
    Flex,
    IconButton,
} from '@chakra-ui/react';
import { EditIcon } from '@chakra-ui/icons';

interface LocationBoxProps {
    destination: string;
    dates: string;
    numberOfAdults: number;
    onEdit: () => void;

}
const LocationBox: React.FC<LocationBoxProps> = (
    {
        destination,
        dates,
        numberOfAdults,
        onEdit
    }
) => {
    return (
        <Flex justify="space-between"
            align="center"
            bg={["#706F6F21", "#706F6F21", '#39383B54']}
            px={[2, 2, '22px']}
            py={[4, 4, '16px']}
            borderRadius={['lg', 'lg', '12px']}

            maxW={["inherit", 'auto']} maxH={['58px', '58px', '64px']}
            w={["auto", "auto", "700px"]}
            border={'1px'}
            borderColor={["#706F6F21", "#706F6F21", "#64646454"]}>
            <Box>
                <Text fontSize={['md', '16px']} fontWeight="800" color='#FFFFFFED'>
                    {destination}
                </Text>
                <Text color="#FFFFFFA1"
                    fontFamily="Poppins"
                    fontSize="13px"
                    fontWeight="600"
                    lineHeight="16px"
                    textAlign="left"
                >
                    {dates} • {numberOfAdults} {numberOfAdults > 1 ? 'Adults' : 'Adult'}
                </Text>
            </Box>
            <IconButton
                aria-label="Edit Trip"
                icon={<EditIcon />}
                variant="ghost"
                colorScheme="whiteAlpha"
                size="lg"
                onClick={onEdit}
            />
        </Flex>
    );
}
export default LocationBox;