import { Box, Flex, Skeleton } from '@chakra-ui/react';
import CountryPageDetailHeader from './CountryPageDetailHeader';
import { pixels } from '../../theme';
import CountryPageDetailImageSlider from './CountryPageImageSlider';
import WeatherDetail from './WeatherDetailI';
import FlightDetail from './FlightDetail';
import CountryDescriptionTab from './CountryDescriptionTab';
import HotelList from './HotelList';
import MapComponent from '../TrMapRenderer';
import { useContext } from 'react';
import { AppContext } from '../../context/AppContext';

const CountryPageDetail = ({ searchDetail, videoHighlightDetail }: any) => {
	console.log('video highlight detail', videoHighlightDetail);
	const { videoHighlightDetailLoading }: any = useContext(AppContext);
	return (
		<Skeleton isLoaded={!videoHighlightDetailLoading}>
			<Flex
				gap={pixels['20pixels']}
				flexDir={{ base: 'column', lg: 'column' }}
				py={pixels['50pixels']}
				px={pixels['20pixels']}
				width={{ base: '100%', lg: '1226px' }}>
				<CountryPageDetailHeader
					ratings={videoHighlightDetail?.ratings}
					comments={videoHighlightDetail?.reviews_count}
					city={
						videoHighlightDetail?.query_entity
							? `${
									(videoHighlightDetail?.query_entity,
									videoHighlightDetail?.country)
							  }`
							: videoHighlightDetail?.country
					}
					country={videoHighlightDetail?.country}
				/>
				<Flex
					gap={pixels['20pixels']}
					flexDir={{ base: 'column', lg: 'row' }}
					w='100%'
					justifyContent={'space-between'}>
					<CountryPageDetailImageSlider items={videoHighlightDetail?.photos} />
					<Flex flexDir={'column'} gap={pixels['20pixels']}>
						<WeatherDetail
							weather={{
								temperature: videoHighlightDetail?.temperature,
								description: videoHighlightDetail?.weather_description,
								yearlyDescription: 'N/A',
							}}
						/>

						<FlightDetail
							flightDetail={{
								hoursInFlight: videoHighlightDetail?.flight_time,
								currentLocationCity: videoHighlightDetail?.from_place,
								flight_booking_link: videoHighlightDetail?.flight_booking_link,
								origin: videoHighlightDetail?.city_origin,
								destination: videoHighlightDetail?.city_destination,
							}}
						/>
						<Box
							position={'relative'}
							h={{ 'base': '180px', 'lg': '162.5px', '2xl': '200px' }}
							w={{ 'base': '100%', 'lg': '313.625px', '2xl': '100%' }}>
							{searchDetail?.location?.latitude ? (
								<MapComponent
									places={[
										{
											lat: videoHighlightDetail?.latitude,
											lng: videoHighlightDetail?.longitude,
										},
									]}
									// longitude={videoHighlightDetail?.longitude}
								/>
							) : (
								''
							)}
						</Box>
					</Flex>
				</Flex>
				{videoHighlightDetail && (
					<CountryDescriptionTab
						description={videoHighlightDetail?.general_climate_info}
						tabs={searchDetail?.tags}
					/>
				)}
				<HotelList
					city={videoHighlightDetail?.city || videoHighlightDetail?.country}
					hotel_booking_link={videoHighlightDetail?.hotel_booking_link}
				/>
			</Flex>
		</Skeleton>
	);
};

export default CountryPageDetail;
