import {
    Flex,
    HStack,
    Heading,
    Button,
    VStack,
    // Drawer,
    // DrawerBody,
    // DrawerOverlay,
    // DrawerContent,
    useDisclosure,
    // useBreakpointValue,
    // Box,
    // IconButton,
    // Image,
} from "@chakra-ui/react";
import BucketCard from "./BucketCard";
import { useContext, useEffect,
    //  useRef,
     
     useState } from "react";
import { AppContext } from "../context/AppContext";
// import CreateItenary from "./CreateItinary";
// import { pixels } from "../theme";
// import ItineraryFooter from './CreateItinary/ItineraryFooter';
import "../create-itenary.css";
import CustomModal from "./CustomModal";

const MyCountryBucket = (props) => {
    const context = useContext(AppContext);
    const { likeSearches, likeEntities } = context;
    const [cities, setCities] = useState([]); // city is going to store [[city , previewImage]]
    const { isOpen, onOpen, onClose } = useDisclosure();

    // const drawerBodyRef = useRef(null);

    // Determine drawer placement based on screen size

    // const drawerPlacement = useBreakpointValue({ base: "bottom", md: "right" });

    // Determine drawer width based on screen size

    // const drawerSize = useBreakpointValue({ base: "full", md: "md" });

    useEffect(() => {
        const updateCities = () => {
			const updatedCities = [...cities]; 
		
			const processEntity = (entity) => {
				const cityName = entity['city'] === undefined ? "Other" : entity['city'];
				const previewImage = entity['previewImage'];
		
				const isUniqueCity = !updatedCities.some(
					([existingCity]) => existingCity === cityName
				);
		
				if (entity["country"] === props.country && isUniqueCity) {
					updatedCities.push([cityName, previewImage]);
				}
			};
		
			likeSearches.forEach(processEntity);
			likeEntities.forEach(processEntity);
		
			setCities(updatedCities);
		};
		
		updateCities();
		// eslint-disable-next-line
    }, [likeSearches, likeEntities, props.country , cities ]);

    return (
        <>
        <VStack w={"100%"} mt={"20px"}>
            <HStack justifyContent={"space-between"} w={"100%"} pb={"10px"}>
                <Heading size={{ base: "20px", lg: "md" }} color={"white"}>
                    {props.country === undefined
                        ? "Miscellaneous"
                        : props.country}
                </Heading>
                <Button
                    fontSize={{ base: "sm", lg: "md" }}
                    bg={"#303030"}
                    color={"#AE7CFF"}
                    borderRadius={"30px"}
                    onClick={onOpen}
                >
                    + Create a Trip
                </Button>
            </HStack>
            <Flex width={'100%'} wrap="wrap" justifyContent="space-between" >
                {cities.map((currentCity, index) => (
                    <BucketCard
                        key={index}
                        cityName={currentCity[0]}
                        previewImage={currentCity[1]}
                        country={props.country}
                    />
                ))}
            </Flex>

            {/* Drawer Component */}
            {/* <Drawer
                isOpen={isOpen}
                placement={drawerPlacement}
                onClose={onClose}
                size={drawerSize}
            >
                <DrawerOverlay />
                <DrawerContent className="create-itinerary-drawer">
                    <Box
                        display={"flex"}
                        h="70px"
                        w="100%"
                        border={"3px solid rgba(0, 0, 0, 0.03)"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        px="20px"
                    >
                        <IconButton onClick={() => onClose()}>
                            <Image src="/icons/arrow_back.svg" />
                        </IconButton>
                        <Box>
                            <IconButton mr="10px">
                                <Image src="/icons/plus_outline.svg" />
                            </IconButton>
                            <IconButton>
                                <Image src="/icons/chat_filled.svg" />
                            </IconButton>
                        </Box>
                    </Box>
                    <Box
                        boxShadow={"0px 7px 7px 0px rgba(0, 0, 0, 0.03)"}
                        height={"32px"}
                        bgColor="rgba(255, 255, 255, 1)"
                        color="rgba(22, 24, 27, 0.63)"
                        display={"flex"}
                        alignItems={"center"}
                        pl={pixels["20pixels"]}
                        lineHeight={"110%"}
                        fontSize={"11px"}
                        w={"100%"}
                    >
                        Create a trip for {props?.country}
                    </Box> */}

                    {/* <DrawerBody
                        px={pixels["20pixels"]}
                        pb={0}
                        ref={drawerBodyRef}
                    >
                        <CreateItenary
                            cities={cities.map((cities) => cities[0])}
                            drawerBodyRef={drawerBodyRef}
                        />
                    </DrawerBody> */}
                    {/* <DrawerFooter
						position={'absolute'}
						bottom={0}
						zIndex={10000000000}
						// pt={pixels['100pixels']}
						px={0}
						// bg='red'
						height={'86px !important'}
						w={'100%'}> */}
                    {/* <ItineraryFooter /> */}
                    {/* </DrawerFooter> */}
                    {/* <DrawerFooter className='it-ft' position={'absolute'} bottom={0}>
						<ItineraryFooter />
					</DrawerFooter> */}
                {/* </DrawerContent>
            </Drawer> */}
        </VStack>
        <CustomModal isOpen={isOpen} onClose={onClose} />
        </>
    );
};

export default MyCountryBucket;
