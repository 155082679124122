import React from 'react';
import { Box, Text, Flex, Circle, Image } from '@chakra-ui/react';

const NotificationBubble: React.FC = () => {
    return (
        <Box position="relative">
            <Box
                position="relative"
                bg="#191919"
                borderRadius="20px"
                mx={2}
                maxWidth="fit-content"
                padding="30px"
                sx={{
                    background: '#191919',
                    // boxShadow: '0 1px 1px rgba(170, 127, 240, 0.5)',
                    border: '1px solid transparent',
                    backgroundClip: 'padding-box',
                    '&::after': {
                        content: '""',
                        position: 'absolute',
                        top: '-1px',
                        bottom: '-1px',
                        left: '-1px',
                        right: '-1px',
                        background: 'linear-gradient(180deg, rgba(73, 72, 76, 0) 4.08%, #926fca 100%)',
                        borderRadius: '21px',
                        zIndex: -1,
                    }
                }}
            >
                <Flex alignItems="center" gap={'13vw'}>
                    <Flex w={'45vw'}>
                        <Text color="#AE7CFF"
                            fontFamily="Poppins"
                            fontSize="17.42px"
                            fontWeight="800"
                            lineHeight=" 20.9px"
                            letterSpacing=" -0.43544822931289673px">
                            Create{" "}
                            <Text
                                as="span"
                                bgGradient="linear-gradient(180deg, rgba(246, 246, 246, 0.83) 0%, rgba(104, 100, 100, 0.83) 100%)"
                                bgClip="text"
                                fontWeight={'600'}>
                                your own tailor made recommendations!
                            </Text>
                        </Text>
                    </Flex>
                    <Box display="flex" alignItems="flex-end">
                        <Image
                            zIndex={'5'}
                            mb={-2}
                            src='/icons/keyboard.svg'
                        />
                        <Circle
                            size="64px"
                            bg={'#373737'}
                            ml={'-20px'}>
                            <Image
                                src='/icons/mic2.svg'
                                alt='mic'
                            />
                        </Circle>
                    </Box>
                </Flex>
            </Box>

            {/* Actual polygon shape */}
            <Box
                position="absolute"
                bottom="-62px"
                left="50%"
                transform="translateX(-50%)"
                width="20px"
                margin="50px auto"

                borderLeft="18px solid transparent"
                borderRight="18px solid transparent"
                borderTop="15px solid #191919" // The main triangle shape
                filter={'drop-shadow(0px 2px 0px rgba(146, 111, 202, 1))'}
                _after={{
                    content: '""',
                    position: "absolute",
                    top: "100%", // Position the shadow just beneath the triangle
                    left: 0,
                    right: 0,
                    height: "10px", // Height of the shadow area
                    background: 'linear-gradient(180deg, rgba(73, 72, 76, 0) 4.08%, #AA7FF0 100%)',
                    zIndex: -1, // Ensure the shadow is behind the triangle
                }}
            />
        </Box>
    );
};

export default NotificationBubble;