// utils/formatDateTime.ts
export const formatDateTime = (date: Date): string => {
  // Formatting hours and minutes
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours % 12 || 12; // Convert 0 hours to 12 for 12 AM
  const formattedMinutes = `${formattedHours}:${minutes} ${ampm}`;

  // Formatting the date
  const day = date.getDate();
  const suffix =
    day === 1 || day === 21 || day === 31
      ? 'st'
      : day === 2 || day === 22
        ? 'nd'
        : day === 3 || day === 23
          ? 'rd'
          : 'th';

  const monthNames = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
  ];
  const formattedDate = `${day}${suffix} ${monthNames[date.getMonth()]}`;

  // Combine the time and the date
  return `${formattedMinutes}, ${formattedDate}`;
};
