import { Tabs, TabList, Tab, TabPanels, TabPanel, VStack, Box, Text, Flex, useDisclosure } from "@chakra-ui/react";
import ListItinerary from "./ListItinerary";
import MyBucketList from "../../pages/MyBucketList";
import ItineraryFooter from "./ItineraryFooter";
import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom"; // Import for managing query parameters
import CustomModal from "../CustomModal";

const NavigationTabs = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [tabIndex, setTabIndex] = useState(0); // State to track the active tab
  const [searchParams] = useSearchParams(); // React-router hook to get query params

  // Effect to set the tab index based on query params
  useEffect(() => {
    const tab = searchParams.get("tab"); // Get the "tab" parameter from the URL
    if (tab === "bucketlists") {
      setTabIndex(0); // Show "Bucketlists" tab
    } else if (tab === "itineraries") {
      setTabIndex(1); // Show "Saved Itineraries" tab
      onOpen(); // Open modal when navigating directly to itineraries tab
    }
  }, [searchParams, onOpen]); // Runs when searchParams change

  const handleTabChange = (index:number) => {
    setTabIndex(index);
    if (index === 1) {
      onOpen(); // Open modal when "Saved Itineraries" tab is clicked
    }
  };

  // Modified onClose to switch back to the "Bucketlists" tab
  const handleCloseModal = () => {
    onClose();
    setTabIndex(0); // Shift back to "Bucketlists" tab
  };

  return (
    <>
      <VStack bg="black" minH={"100dvh"} w={"100vw"} pt={2}>
        <Box
          position={"fixed"}
          top={0}
          left={0}
          w={"100vw"}
          h={"50vh"}
          zIndex={"2"}
          bg={"linear-gradient(180deg, #252128 0%, rgba(36, 29, 50, 0) 100%)"}
        />
        <Tabs
          isLazy
          variant={"unstyled"}
          zIndex={5}
          index={tabIndex} // Set the active tab index
          onChange={handleTabChange} // Handle tab change
        >
          <TabList justifyContent="space-evenly" w={"100vw"}>
            <Tab
              sx={{ borderBottom: "1px solid #FFFFFF21", w: "100vw" }}
              _selected={{
                borderBottom: "2px solid #FFFFFFED",
              }}
            >
              <Flex direction="column" alignItems="flex-start">
                <Text
                  color="#FFFFFF54"
                  fontFamily="Poppins"
                  fontSize="14px"
                  fontWeight="500"
                  lineHeight="18.62px"
                  letterSpacing="-0.5611507892608643px"
                  textAlign="left"
                >
                  Your
                </Text>
                <Text
                  fontFamily="Poppins"
                  fontSize="14px"
                  fontWeight="700"
                  lineHeight="18.62px"
                  letterSpacing="-0.5611507892608643px"
                  textAlign="left"
                  as="span"
                  color="#FFFFFFED"
                >
                  Bucketlists
                </Text>
              </Flex>
            </Tab>
            <Tab
              isFocusable={true}
              sx={{ borderBottom: "1px solid #FFFFFF21", w: "100vw" }}
              _selected={{
                borderBottom: "2px solid #FFFFFFED", // Bold border under the text
              }}
            >
              <Flex direction="column" alignItems="flex-start">
                <Text
                  color="#FFFFFF54"
                  fontFamily="Poppins"
                  fontSize="14px"
                  fontWeight="500"
                  lineHeight="18.62px"
                  letterSpacing="-0.5611507892608643px"
                  textAlign="left"
                >
                  Saved
                </Text>
                <Text
                  fontFamily="Poppins"
                  fontSize="14px"
                  fontWeight="700"
                  lineHeight="18.62px"
                  letterSpacing="-0.5611507892608643px"
                  textAlign="left"
                  as="span"
                  color="#FFFFFFED"
                >
                  Itineraries
                </Text>
              </Flex>
            </Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <MyBucketList />
            </TabPanel>

            {/* Panel for Saved Itineraries */}
            <TabPanel>
              <ListItinerary />
            </TabPanel>
          </TabPanels>
        </Tabs>
        <Box mt={20} />
      </VStack>
      <ItineraryFooter />

      {/* Modal */}
      <CustomModal isOpen={isOpen} onClose={handleCloseModal} />
    </>
  );
};

export default NavigationTabs;
