import React, { useContext, useEffect, useState } from 'react';
import {
	Avatar,
	Box,
	Text,
	Image,
	// Button,
	Drawer,
	DrawerBody,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	useDisclosure,
	Flex,
} from '@chakra-ui/react';
import TrTransparentButton from './TrTransparentButton';
import { pixels } from '../theme';
import CityFlightDetail from './CityFlightDetail';
import { AppContext } from '../context/AppContext';
import { Link } from 'react-scroll';
import CardSlider from './CardSlider';
import SearchBox from './SearchBox';
import LikeAndShare from './LikeAndShare';

interface VideoHeaderProps {
	videos: any;
}

const VideoHeader = ({ videos }: VideoHeaderProps) => {
	const { currentVideoIndex, searchDetail, searchPlaceHolder }: any =
		useContext(AppContext);
	const [isAtTop, setIsAtTop] = useState(true);
	const { isOpen, onOpen, onClose } = useDisclosure(); // Manage drawer state

	useEffect(() => {
		const handleScroll = () => {
			console.log(window.scrollY);
			setIsAtTop(window.scrollY === 0);
		};

		window.addEventListener('scroll', handleScroll);
		return () => window.removeEventListener('scroll', handleScroll);
	}, []);

	useEffect(() => {
		console.log('at Top', isAtTop);
	}, [isAtTop]);
	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'space-between',
				width: '100%',
				flexDirection: 'column',
				gap: pixels['20pixels'],
			}}>
			<Box display={'flex'} w='100%'>
				<Box display={'flex'} w={'100%'}>
					<Avatar w={'37px'} h='37px' my='auto' mr={pixels['10pixels']} />
					<Box
						py={pixels['10pixels']}
						display='flex'
						flexDir={'column'}
						justifyContent={'space-between'}>
						<Text
							fontFamily='Poppins'
							fontSize='13px'
							fontWeight='400'
							lineHeight='18.51px'
							mr='4px'
							color='rgba(255, 255, 255, 0.63)'>
							Video curated by
						</Text>
						{videos && videos.length ? (
							<Text
								color={'white'}
								fontFamily='Poppins'
								fontSize='13px'
								fontWeight='700'
								lineHeight='18.51px'>
								{videos[currentVideoIndex] &&
									videos[currentVideoIndex]?.video_play_url?.author}
							</Text>
						) : (
							''
						)}
					</Box>
				</Box>

				<TrTransparentButton
					px='0'
					mx={0}
					minW={'max-content'}
					onClick={onOpen}>
					<Text mr={pixels['10pixels']} fontWeight={300} fontSize='13px'>
						See all{' '}
					</Text>
					<Image
						src='/icons/ArrowRight.svg'
						sx={{
							width: '16.42px',
							height: '11.31px',
							my: 'auto',
						}}
					/>
				</TrTransparentButton>
			</Box>
			<Flex
				justifyContent={'space-between'}
				className='like-and-share'
				zIndex={52}>
				{/* <LikeAndShare /> */}
				{searchDetail && searchDetail.videos && (
					<Text
						noOfLines={2}
						sx={{
							display: { base: 'inherit', md: 'none' },
							color: '#fff',
							// fontSize: '20px',
							fontWeight: 700,
							// lineHeight: '25px',
							// letterSpacing: '0.23455415666103363px',
							// textAlign: 'left',
						}}>
						{searchDetail?.videos[currentVideoIndex]?.title
							? searchDetail?.videos[currentVideoIndex]?.title
							: searchDetail?.videos[currentVideoIndex]?.video_play_url?.title}
					</Text>
				)}

				<Box mt='0px'>
					<LikeAndShare />
				</Box>
			</Flex>

			<CityFlightDetail />
			<Box
				bottom='50px'
				position='relative'
				display={'flex'}
				mt={pixels['20pixels']}
				justifyContent={'center'}>
				<Box
					as={Link}
					to='section2'
					spy={true}
					smooth={true}
					offset={-100}
					duration={1000}
					style={{ cursor: 'pointer', color: '#fff' }}>
					<TrTransparentButton visibility={isAtTop ? 'visible' : 'hidden'}>
						SWIPE UP FOR MORE
					</TrTransparentButton>
				</Box>
				<Image
					visibility={isAtTop ? 'visible' : 'hidden'}
					src='/icons/ArrowUp.svg'
					sx={{ width: '7.36px', height: '13.09px', my: 'auto' }}
				/>
			</Box>

			{/* Bottom Drawer */}
			<Drawer
				isFullHeight={true}
				size={'full'}
				placement='right'
				onClose={onClose}
				isOpen={isOpen}>
				<DrawerOverlay />
				<DrawerContent>
					<DrawerHeader
						background='#000000'
						border={'none'}
						display={'flex'}
						alignItems={'center'}
						justifyContent={'center'}
						borderBottomWidth='none'
						paddingTop={'20px'}
						pb='60px'>
						<SearchBox
							placeholder={searchPlaceHolder}
							onClose={onClose}
							dontDoInitSearch={true}
						/>
					</DrawerHeader>
					<DrawerBody background='#000000'>
						<CardSlider videos={searchDetail?.videos} onClose={onClose} />
					</DrawerBody>
				</DrawerContent>
			</Drawer>
		</Box>
	);
};

export default VideoHeader;
