import { Flex, Link, Text } from '@chakra-ui/react';
import CountryContentBox from './CountryContentBox';
import { pixels } from '../../theme';
// import TrHyperLink from '../TrHyperLink';

const FlightDetail = ({ flightDetail }: any) => {
	return (
		<CountryContentBox>
			<Flex justifyContent={'center'} w='100%' flexDir={'column'}>
				<Flex align=''>
					<svg
						width='34'
						height='31'
						viewBox='0 0 34 31'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'>
						<path
							d='M0 8.73258L2.42138 13.9286L3.12786 15.4498L2.42138 16.9653L0 22.1784H2.68917L7.63432 17.4156C9.78791 17.5808 12.0325 17.729 14.2374 17.8657L9.22958 30.1265H12.6708L21.9292 18.2931H21.9349C26.1909 18.5039 29.3584 18.6235 29.8715 18.6235C31.6206 18.6235 33.0449 17.1992 33.0449 15.4501C33.0449 13.701 31.6206 12.2766 29.8715 12.2766C29.3587 12.2766 26.1854 12.3963 21.9294 12.6014H21.9237L12.6712 0.773438H9.22995L14.2323 13.0284C12.0332 13.1651 9.7882 13.3132 7.63468 13.4785L2.68953 8.73242L0 8.73258Z'
							fill='#161517'
						/>
					</svg>
					&nbsp;
					<Text
						color='#161517'
						fontFamily='Poppins'
						fontSize={{ base: pixels['14pixels'], lg: '32px' }}
						fontWeight='700'
						lineHeight='24px'
						textAlign='left'
						mt={pixels['5pixels']}>
						{flightDetail?.hoursInFlight}
					</Text>
				</Flex>
				<Text
					mt={pixels['10pixels']}
					color='#16151754'
					fontFamily='Poppins'
					fontSize={{ base: pixels['12pixels'], lg: pixels['16pixels'] }}
					fontWeight='500'
					lineHeight='16px'
					textAlign='left'>
					from {flightDetail?.currentLocationCity}
				</Text>
				<Flex mt={pixels['30pixels']} w='100%' justifyContent={'space-between'}>
					<Text
						color={'#16151754'}
						fontFamily='Poppins'
						fontSize={{ base: pixels['12pixels'], lg: pixels['20pixels'] }}
						fontWeight='500'
						lineHeight='23px'
						textAlign='left'
						my='auto'>
						Prices start from{' '}
						<Text
							fontSize={{ base: pixels['12pixels'], lg: 'inherit' }}
							fontWeight={500}
							as='span'
							color='#161517;
'>
							₹{'N/A'}
						</Text>
					</Text>
					<Link target='_blank' href={flightDetail?.flight_booking_link}>
						Check
					</Link>
					{/* <a
						style={{
							color: 'linear-gradient(180deg, #AE7CFF 0%, #6732BE 100%)',
							fontWeight: 500,
						}}
						color='linear-gradient(180deg, #AE7CFF 0%, #6732BE 100%)'
						target='_blank'
						href={flightDetail?.flight_booking_link}
						rel='noreferrer'>
						Check
					</a> */}
				</Flex>
			</Flex>
		</CountryContentBox>
	);
};

export default FlightDetail;
