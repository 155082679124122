import { Box, Image, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { AppContext } from '../context/AppContext';
import { pixels } from '../theme';

const BucketCard = (props) => {
	const navigate = useNavigate();
	const context = useContext(AppContext);

	const { setBucketListCard } = context;

	const showCardDetails = () => {
		console.log(props.country)
		console.log(props.cityName)
		// const country = props.country===undefined ? 'Miscellaneous':props.country
		// const city = props.cityName===undefined ? 'Unknown':props.cityName
		setBucketListCard([props.country , props.cityName]);
		navigate('/home/bucketCardDetail');
	};

	return (
		<Box
			borderRadius={{ base: pixels['20pixels'] }}
			border='3px solid rgba(49, 48, 48, 1)'
			position='relative'
			width={{ base: '158px', lg: '130px' }}
			height={{ base: '158px', lg: '130px' }}
			cursor={'pointer'}
			onClick={showCardDetails}>
			<Image
				src={props.previewImage}
				alt='Description'
				objectFit='cover'
				width='100%'
				height='100%'
				opacity={0.4}
				borderRadius='20px'
			/>
			<Box
				position='absolute'
				top='0'
				left='0'
				width='100%'
				height='100%'
				display='flex'
				alignItems='center'
				justifyContent='center'>
				<Text fontSize='16px' fontWeight='bold' color='white'>
					{props.cityName}
				</Text>
			</Box>
		</Box>
	);
};

export default BucketCard;
