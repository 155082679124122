import React from 'react';
import { Box, Flex, Image, Text } from '@chakra-ui/react';

type LocationButtonProps = {
  location: {
    videoSrc: string;
    title: string;
    description: string;
  };
  isSelected: boolean;  // New prop to indicate if the button is selected
  onClick: () => void;
};

const LocationButton: React.FC<LocationButtonProps> = ({ location, isSelected, onClick }) => {
  return (
    <Box
      boxSize="72px"
      borderRadius="12px"
      border={isSelected ? '1.4px solid #FFFFFFA1' : '1px solid #FFFFFF21'}  // Conditional border
      cursor="pointer"
      onClick={onClick}
      transition="all 0.3s ease"
      _hover={{ transform: 'scale(1.05)' }}
      position="relative"  // Enable absolute positioning for children
    >
      <Image
        boxSize="70px"
        objectFit="cover"
        borderRadius="12px"
        src={`/images/${location.title.toLowerCase().replace(' ', '-')}.png`}
        filter="brightness(0.6)"
      />
      <Flex
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"  // Center the text
        align="center"
        justify="center"
      >
        <Text
          textAlign="center"
          fontFamily="Poppins"
          fontSize="11px"
          fontWeight="700"
          lineHeight="16.5px"
          color="#FFFFFFD4"
          whiteSpace="normal"   // Allows text to wrap
        >
          {location.title}
        </Text>
      </Flex>
    </Box>
  );
};

export default LocationButton;
