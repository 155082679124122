import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
// import TrHyperLink from '../TrHyperLink';
import { pixels } from '../../theme';

interface HotelListItemProps {
	title: string;
	startingPrice: string;
	link: string;
	ip?: string;
}

const HotelListItem: React.FC<HotelListItemProps> = ({
	title,
	startingPrice,
	link,
}) => {
	return (
		<Box
			borderTop='1px solid #16151721'
			py={{ base: pixels['14pixels'], lg: pixels['20pixels'] }}>
			<Flex w='100%' justifyContent={'space-between'}>
				<Box>
					<Text
						fontFamily='Poppins'
						fontSize={{ base: pixels['16pixels'], lg: '28px' }}
						fontWeight='700'
						lineHeight='34.82px'
						textAlign='left'
						color='#151416'>
						{title}
					</Text>
					<Text
						fontFamily='Poppins'
						fontSize={{ base: pixels['12pixels'], lg: '16px' }}
						fontWeight='500'
						lineHeight='16px'
						textAlign='left'
						color='#15141654'>
						Starting from ₹{startingPrice}
					</Text>
				</Box>
				{/* fontSize={{ base: pixels['12pixels'], lg: 'inherit' }} */}
				{/* to={link} */}
				{/* mr={{ base: 0, lg: pixels['50pixels'] }} */}
				<a
					style={{
						cursor: 'pointer',
						color:
							'linear-gradient(180deg, #AE7CFF 0%, #6732BE 100%) !important',
					}}
					href={link}
					target='_blank'
					rel='noreferrer'>
					Check Now
				</a>
			</Flex>
		</Box>
	);
};

export default HotelListItem;
