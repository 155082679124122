import React, { createContext, useState, ReactNode } from 'react';

export interface VideoContextType {
	openHighlightMap: boolean;
	setOpenHighlightMap: React.Dispatch<React.SetStateAction<boolean>>;

	currentHighlightIndex: number;
	setCurrentHighlightIndex: React.Dispatch<React.SetStateAction<number>>;
}

export const MobileMapHighlightContext = createContext<
	VideoContextType | undefined
>(undefined);

interface AppContextProviderProps {
	children: ReactNode;
}

export const MobileMapHighlightContextProvider: React.FC<
	AppContextProviderProps
> = ({ children }) => {
	const [openHighlightMap, setOpenHighlightMap] = useState<boolean>(true);
	const [currentHighlightIndex, setCurrentHighlightIndex] = useState<number>(0);

	return (
		<MobileMapHighlightContext.Provider
			value={{
				openHighlightMap,
				setOpenHighlightMap,
				currentHighlightIndex,
				setCurrentHighlightIndex,
			}}>
			{children}
		</MobileMapHighlightContext.Provider>
	);
};

export default MobileMapHighlightContextProvider;
