import React, { createContext, useState, ReactNode } from 'react';

export interface ShareModalProps {
	// onClose: () => void;
	url: string;
	title: string;
	handleShareClick: () => void;
	handleCloseModal: () => void;
}

export interface AppContextType {
	otherPlaces: any[];
	setOtherPlaces: React.Dispatch<React.SetStateAction<any[]>>;
	topPlaces: any[];
	setTopPlaces: React.Dispatch<React.SetStateAction<any[]>>;
	searchDetail: any;
	setSearchDetail: React.Dispatch<React.SetStateAction<any>>;
	searchDetailLoading: boolean;
	setSearchDetailLoading: React.Dispatch<React.SetStateAction<boolean>>;
	currentVideoIndex: number;
	setCurrentVideoIndex: React.Dispatch<React.SetStateAction<number>>;
	shareModal: ShareModalProps;
	setShareModal: React.Dispatch<React.SetStateAction<any>>;
	isShareModalOpen: any;
	likeStatus: any[];
	setLikeStatus: React.Dispatch<React.SetStateAction<any[]>>;
	videoHighlightDetail: any;
	setVideoHighlightDetail: React.Dispatch<React.SetStateAction<any>>;
	videoHighlightDetailLoading: boolean;
	setVideoHighlightDetailLoading: React.Dispatch<React.SetStateAction<any>>;
	likeSearches: any[];
	setLikeSearches: React.Dispatch<React.SetStateAction<any[]>>;
	likeEntities: any[];
	setLikeEntities: React.Dispatch<React.SetStateAction<any[]>>;
	bucketListCard: any[];
	setBucketListCard: React.Dispatch<React.SetStateAction<any[]>>;
	ip: string;
	setIp: React.Dispatch<React.SetStateAction<string>>;
	chatEntries: any[];
	setChatEntries: React.Dispatch<React.SetStateAction<any[]>>;
	stories: any[];
	setStories: React.Dispatch<React.SetStateAction<any[]>>;
	influencers: any[];
	setInfluencers: React.Dispatch<React.SetStateAction<any[]>>;
	storyList: any[];
	setStoryList: React.Dispatch<React.SetStateAction<any[]>>;
	isLoading: boolean;
	setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
	dateTime: string;
	setDateTime: React.Dispatch<React.SetStateAction<string>>;
	rOpen: boolean;
	setROpen: React.Dispatch<React.SetStateAction<boolean>>;
	lOpen: boolean;
	setLOpen: React.Dispatch<React.SetStateAction<boolean>>;
	currChatId: string;
	setCurrChatId: React.Dispatch<React.SetStateAction<string>>;
	title: any[];
	setTitle: React.Dispatch<React.SetStateAction<any[]>>;
	previousChatLoading: boolean;
	setPreviousChatLoading: React.Dispatch<React.SetStateAction<boolean>>;
	isCollection: boolean;
	setIsCollection: React.Dispatch<React.SetStateAction<boolean>>;
	onBehalf: any;
	setOnBehalf: React.Dispatch<React.SetStateAction<any>>;
	searchPlaceHolder: string;
	setSearchPlaceHolder: React.Dispatch<React.SetStateAction<string>>;
	entitesDetail:any[];
	setEntitesDetail:React.Dispatch<React.SetStateAction<any[]>>;
}

export const AppContext = createContext<AppContextType | undefined>(undefined);

interface AppContextProviderProps {
	children: ReactNode;
}

export const AppContextProvider: React.FC<AppContextProviderProps> = ({
	children,
}) => {
	const [otherPlaces, setOtherPlaces] = useState<any[]>([]);
	const [topPlaces, setTopPlaces] = useState<any[]>([]);
	const [searchDetail, setSearchDetail] = useState<any>({});
	const [videoHighlightDetail, setVideoHighlightDetail] = useState<any>({});
	//
	const [isShareModalOpen, setIsShareModalOpen] = useState<any>(undefined);
	const [videoHighlightDetailLoading, setVideoHighlightDetailLoading] =
		useState<boolean>(false);

	const handleShareClick = async (url?: any, title?: any) => {
		console.log(url, title);
		setIsShareModalOpen({ url, title });
		console.log('is share modal open', isShareModalOpen);
		// console.log('share modal props', shareModal);
		// setIsShareModalOpen({ url, title });
		// setShareModal({ ...shareModal, url: url, title: title });
	};

	const handleCloseModal = () => {
		setIsShareModalOpen(false);
	};

	const [shareModal, setShareModal] = useState<ShareModalProps>({
		url: '',
		title: '',
		handleCloseModal,
		handleShareClick,
	});
	const [searchDetailLoading, setSearchDetailLoading] =
		useState<boolean>(false);
	const [currentVideoIndex, setCurrentVideoIndex] = useState<number>(0);
	const [likeStatus, setLikeStatus] = useState<any[]>([]);
	const [likeEntities, setLikeEntities] = useState<any[]>([]);
	const [likeSearches, setLikeSearches] = useState<any[]>([]);
	const [bucketListCard, setBucketListCard] = useState<any[]>([]);
	const [ip, setIp] = useState<string>('');
	const [chatEntries, setChatEntries] = useState<any[]>([]);
	const [stories, setStories] = useState<any[]>([]);
	const [influencers, setInfluencers] = useState<any[]>([]);
	const [storyList, setStoryList] = useState<any[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [dateTime, setDateTime] = useState<string>('');
	const [rOpen, setROpen] = useState<boolean>(false);
	const [lOpen, setLOpen] = useState<boolean>(true);
	const [currChatId, setCurrChatId] = useState<string>('');
	const [title, setTitle] = useState<any[]>([]);
	const [previousChatLoading, setPreviousChatLoading] =
		useState<boolean>(false);
	const [isCollection, setIsCollection] = useState<boolean>(false);
	const [onBehalf, setOnBehalf] = useState<any>({});
	const [searchPlaceHolder, setSearchPlaceHolder] = useState('');
	const [entitesDetail,setEntitesDetail]=useState<any[]>([]);
	return (
		<AppContext.Provider
			value={{
				bucketListCard,
				setBucketListCard,
				likeEntities,
				setLikeEntities,
				likeSearches,
				setLikeSearches,
				otherPlaces,
				setOtherPlaces,
				topPlaces,
				setTopPlaces,
				searchDetail,
				setSearchDetail,
				searchDetailLoading,
				setSearchDetailLoading,
				currentVideoIndex,
				setCurrentVideoIndex,
				shareModal,
				setShareModal,
				isShareModalOpen,
				likeStatus,
				setLikeStatus,
				videoHighlightDetail,
				setVideoHighlightDetail,
				videoHighlightDetailLoading,
				setVideoHighlightDetailLoading,
				ip,
				setIp,
				chatEntries,
				setChatEntries,
				stories,
				setStories,
				influencers,
				setInfluencers,
				storyList,
				setStoryList,
				isLoading,
				setIsLoading,
				dateTime,
				setDateTime,
				rOpen,
				setROpen,
				lOpen,
				setLOpen,
				currChatId,
				setCurrChatId,
				title,
				setTitle,
				previousChatLoading,
				setPreviousChatLoading,
				isCollection,
				setIsCollection,
				onBehalf,
				setOnBehalf,
				searchPlaceHolder,
				setSearchPlaceHolder,
				entitesDetail,
				setEntitesDetail
			}}>
			{children}
		</AppContext.Provider>
	);
};
