import {
	Box,
	Flex,
	VStack,
	Button,
	Text,
	Slide,
	HStack,
	IconButton,
	Image,
} from '@chakra-ui/react';
import { keyframes } from '@emotion/react';
import { useState, Suspense, useEffect, useContext, useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import InfluencerId from './InfluencerId';
import InfLocationDetail from './InfLocationDetail';
import useAxios from '../../hooks/useAxios';
import ReactPlayer from 'react-player/lazy';
import { AppContext } from '../../context/AppContext';

const moveUpDown = keyframes`
  0% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0); }
`;

// import { IconButton, Image } from '@chakra-ui/react';
// import { useNavigate, useLocation } from 'react-router-dom';
// import { useEffect, useRef } from 'react';

const BackButton = () => {
	const navigate = useNavigate();
	// const location = useLocation();
	const prevLocation = useRef(null);

	// Update the ref with the current location on each render
	// useEffect(() => {
	// 	//@ts-ignore
	// 	prevLocation.current = location.pathname;
	// }, [location]);

	return (
		<IconButton
			position='absolute'
			aria-label='back'
			top='4%'
			left='3%'
			zIndex={11}
			icon={<Image src='/icons/Back_Icon_White.svg' alt='back' />}
			variant='ghost'
			size='lg'
			onClick={() => {
				// Check if the previous location is defined and not on the home page
				//@ts-ignore
				
				if (prevLocation.current && !prevLocation.current.includes('/home')) {
					navigate(prevLocation.current);
				} else {
					navigate('/home'); // Navigate to 'home' if there's no previous location or it's on 'home'
				}
			}}
			borderRadius='full'
			dropShadow='0px 4px 10px rgba(0, 0, 0, 0.6)'
			_hover={{ bg: 'transparent' }}
			_active={{ bg: 'transparent' }}
			_focus={{ boxShadow: 'none' }}
		/>
	);
};

const InfluencersVideo: React.FC = () => {
	const { index } = useParams<{ index?: string; link?: string }>();

	const navigate = useNavigate();
	const { likeSearches, setLikeSearches }: any = useContext(AppContext);
	const { fetchData } = useAxios();
	const [isLiked, setIsLiked] = useState(false);
	const location = useLocation();

	// Parse query parameters
	const queryParams = new URLSearchParams(location.search);
	const link = queryParams.get('link');
	// eslint-disable-next-line
	const influencerDataState = location.state?.influencerData ?? {
		channelName: '',
		chapter_segment: '[]',
		highlights: [],
		score: 0.0,
		source: '',
		tags: [],
		video_url: '',
		video_play_url: {
			title: '',
			is_audio: false,
			author: '',
			source: '',
			previewImage: '',
			media_url: '',
		},
	};
	const [influencerData, setInfluencerData] = useState(influencerDataState);
	const [isPlaying, setIsPlaying] = useState(false);

	const currentIndex = parseInt(index ?? '0', 10);
	const initInfluencerState = influencerData[currentIndex];
	const [influencer, setInfluencer] = useState(initInfluencerState);
	const [isPlaceOpen, setIsPlaceOpen] = useState(false);
	const [showAddedToBucket, setShowAddedToBucket] = useState(false);

	const fetchVideoDetail = async () => {
		const { data, statusCode, error } = await fetchData({
			method: 'POST',
			url: 'ai/video-link-data',
			options: {
				data: {
					videos: [link],
				},
				headers: { 'Content-Type': 'application/json' },
			},
			auth: false,
		});

		if (statusCode === 200) {
			if (data && data.videos) {
				setInfluencer(data?.videos[0]);
				setInfluencerData(data.videos);
			}
		} else {
			console.log(error);
		}
	};
	useEffect(() => {
		// alert(link);
		if (link) {
			fetchVideoDetail();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [link]);

	const handleSwipeUp = () => {
		setIsPlaceOpen(true);
	};

	// console.log("videoUrl in InfluencerVideo is ", videoUrl);
	const handlers = useSwipeable({
		onSwipedUp: handleSwipeUp,
		trackMouse: true,
	});
	// useEffect(() => {
	// 	if (!influencerData || !link) {
	// 		navigate('/home'); // Adjust this to your actual route
	// 	}
	// }, [influencerData, navigate, link]);

	useEffect(() => {
		if (influencerData.length > 0 && influencer) {
			// Ensure influencerData has content
			console.log('check??');
			setIsLiked(false);
			for (let video of likeSearches) {
				if (video['url'] === influencer.video_url) {
					setIsLiked(true);
				}
			}
		}
	}, [likeSearches, currentIndex, influencerData, influencer]); // Add influencerData to dependencies

	// if (!influencer) return <Text>No influencer data available</Text>;

	// Handle next and previous functions safely
	const handleNext = () => {
		const nextIndex = (currentIndex + 1) % influencerData.length;
		navigate(`/home/influencer/${nextIndex}`, { state: { influencerData } });
	};

	const handlePrevious = () => {
		const prevIndex =
			(currentIndex - 1 + influencerData.length) % influencerData.length;
		navigate(`/home/influencer/${prevIndex}`, { state: { influencerData } });
	};

	// if (!influencerData || !influencerData[currentIndex]) {
	//   return <Text>No influencer data available</Text>;
	// }

	const likeVideo = async (isLiked: boolean) => {
		const token = localStorage.getItem('token');
		if (token) {
			const body = {
				title: influencerData[currentIndex]?.title,
				city:
					influencerData[currentIndex]?.city === ''
						? undefined
						: influencerData[currentIndex]?.city,
				country:
					influencerData[currentIndex]?.country === ''
						? undefined
						: influencerData[currentIndex]?.country,
				description: influencerData[currentIndex]?.description,
				tags: influencerData[currentIndex]?.tags,
				url: influencerData[currentIndex]?.video_url,
				previewImage: influencerData[currentIndex]?.previewImage,
				location: influencerData?.location,
				flightStartingPrice: influencerData[currentIndex]?.flightStartingPrice,
			};

			try {
				if (isLiked) {
					// Dislike the influencer
					await fetchData({
						method: 'POST',
						url: 'user/dislikeSearch',
						options: {
							data: { ...body, token },
							headers: { 'Content-Type': 'application/json' },
						},
						auth: false,
					});

					const tempLikeVideos = [];
					for (let video of likeSearches) {
						if (!(video['url'] === body.url)) {
							tempLikeVideos.push(video);
						}
					}
					setLikeSearches(tempLikeVideos);
				} else {
					// Like the influencer
					await fetchData({
						method: 'POST',
						url: 'user/likeSearch',
						options: {
							data: { ...body, token },
							headers: { 'Content-Type': 'application/json' },
						},
						auth: false,
					});
					setLikeSearches([...likeSearches, body]);
					setShowAddedToBucket(true);
					setTimeout(() => {
						setShowAddedToBucket(false);
					}, 3000);
				}
			} catch (error) {
				console.error('Error while liking/disliking the influencer:', error);
			}
		}
	};

	const handlePlayPause = () => {
		setIsPlaying((prevState) => !prevState);
	};

	return (
		<VStack
			id='story-container'
			width='100%'
			height='100vh'
			bg='black'
			overflowY='hidden'
			position={'absolute'}>
			<Box
				position='absolute'
				width='100%'
				height='100%'
				top='0'
				left='0'
				zIndex={10} // Ensure it's above the children
				bgGradient='linear-gradient(180deg, #111417 0%, rgba(7, 9, 11, 0) 30%)'
				pointerEvents='none' // Prevent interaction with overlay
			/>
			<Box
				position='absolute'
				width='100%'
				height='100%'
				top='0'
				left='0'
				zIndex={10} // Ensure it's above the children
				bgGradient='linear-gradient(0deg, #111417 7.37%, rgba(7, 9, 11, 0) 80%)'
				pointerEvents='none' // Prevent interaction with overlay
			/>
			<BackButton />
			<Box
				position='absolute'
				top={0}
				left={0}
				right={0}
				bottom={0}
				{...handlers}>
				<ReactPlayer
					zIndex={-1}
					url={influencerData[currentIndex]?.video_play_url?.['media_url']}
					playing={isPlaying} // Autoplay
					muted={false} // Mute the video
					loop={true} // Loop the video
					width='100%' // Full width
					height='100vh' // Full height
					style={{ position: 'absolute', top: 0, left: 0 }}
					config={{
						file: {
							attributes: {
								style: {
									width: '100%',
									height: '100vh',
									objectFit: 'cover',
								},
							},
						},
					}}
				/>

				{!isPlaying && (
					<Button
						onClick={handlePlayPause}
						position={'absolute'}
						top={'50%'}
						left={'50%'}
						variant={"ghost"}
						transform={'translate(-50%, -50%)'}
						boxSize={30}
						zIndex={1}>
						▶
					</Button>
				)}
				{isPlaying && (
					<Button
						onClick={handlePlayPause}
						position='absolute'
						top='50%'
						left='50%'
						variant="ghost"
						visibility={'initial'}
						transform='translate(-50%, -50%)'
						boxSize={10}
						zIndex={1}>
						||
					</Button>
				)}

				{!link && (
					<IconButton
						aria-label='Like'
						icon={<Image src='/icons/left.svg' alt='like' />}
						variant='ghost'
						size='lg'
						position='absolute'
						top='50%'
						left='5%'
						transform='translateY(-50%)'
						borderRadius='full'
						_hover={{ bg: 'transparent' }}
						_active={{ bg: 'transparent' }}
						_focus={{ boxShadow: 'none' }}
						isDisabled={currentIndex === 0}
						onClick={handlePrevious}
					/>
				)}
				{!link && (
					<IconButton
						aria-label='Like'
						icon={<Image src='/icons/right.svg' alt='like' />}
						variant='ghost'
						size='lg'
						position='absolute'
						top='50%'
						right='5%'
						transform='translateY(-50%)'
						borderRadius='full'
						_hover={{ bg: 'transparent' }}
						_active={{ bg: 'transparent' }}
						_focus={{ boxShadow: 'none' }}
						isDisabled={currentIndex === influencerData.length - 1}
						onClick={handleNext}
					/>
				)}

				<VStack
					position='fixed'
					bottom={'3%'}
					left={'0%'}
					zIndex={11}
					spacing={6}
					pr={5}>
					<HStack
						alignItems={'center'}
						justifyContent={'space-between'}
						w={'100vw'}
						px={4}
						position={'relative'}>
						<Flex flexDir={'column'} my={4}>
							<Box py={5}>
								<InfluencerId infId={influencerData?.[currentIndex]} />
							</Box>
							<Text
								fontFamily='Poppins'
								fontSize='20px'
								fontWeight='700'
								lineHeight='25px'
								maxW={'330px'}
								color={'#FFFFFFED'}
								letterSpacing='0.23455415666103363px'
								textAlign='left'>
								{influencerData[currentIndex]?.['title']}
							</Text>
						</Flex>
						<HStack spacing={0} zIndex={20}>
							<IconButton
								aria-label='Like'
								icon={
									<Image
										src={isLiked ? '/icons/like.svg' : '/icons/white-heart.svg'}
										alt='like'
									/>
								}
								variant='ghost'
								size='lg'
								borderRadius='full'
								onClick={(e) => {
									e.stopPropagation();
									likeVideo(isLiked);
								}}
								_hover={{ bg: 'transparent' }}
								_active={{ bg: 'transparent' }}
								_focus={{ boxShadow: 'none' }}
							/>
							<IconButton
								aria-label='Share'
								icon={<Image src='/icons/white-share.svg' alt='share' />}
								variant='ghost'
								size='lg'
								borderRadius='full'
								_hover={{ bg: 'transparent' }}
								_active={{ bg: 'transparent' }}
								_focus={{ boxShadow: 'none' }}
							/>
						</HStack>
					</HStack>
					<HStack
						spacing={0}
						position={'relative'}
						zIndex={11}
						justifyContent={'center'}
						animation={`${moveUpDown} 1s infinite`}>
						<Text fontSize='13px' color='white' fontWeight='500'>
							SCROLL UP FOR MORE
						</Text>
						<Button bg='inherit' variant='link' onClick={handleSwipeUp}>
							<Image src='/icons/ArrowUp.svg' alt='swipe up' />
						</Button>
					</HStack>
				</VStack>
			</Box>

			<Slide direction='bottom' in={isPlaceOpen} style={{ zIndex: 20 }}>
				<Box
					p={4}
					bg='#000000'
					h={'100vh'}
					overflowY={'auto'}
					overflowX={'hidden'}>
					<Suspense fallback={<div>Loading...</div>}>
						<InfLocationDetail
							handleBackButton={() => setIsPlaceOpen(false)}
							influencerData={influencer || influencerData[currentIndex]}
						/>
					</Suspense>
				</Box>
			</Slide>
			{showAddedToBucket && (
				<Flex
					position='fixed'
					bottom='10px'
					left='50%'
					transform='translateX(-50%)'
					bg='#AE7CFF'
					w={'300px'}
					h={'40px'}
					color='white'
					borderRadius='8px'
					alignItems={'center'}
					justifyContent={'space-around'}
					zIndex={200}>
					<Text
						fontFamily='Poppins'
						fontSize='18px'
						fontWeight={'medium'}
						lineHeight='19px'
						letterSpacing='-0.5611507892608643px'
						textAlign='left'>
						Added to bucket list
					</Text>
					<Button
						variant={'ghost'}
						color={'white'}
						_hover={{ bg: 'none' }}
						fontWeight={'medium'}
						onClick={() => navigate('/home/navigate')}>
						View
					</Button>
				</Flex>
			)}
		</VStack>
	);
};

export default InfluencersVideo;
