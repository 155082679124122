//@ts-nocheck
import React, { useEffect, useState, memo, useRef } from 'react';
import {
	MapContainer,
	TileLayer,
	Marker,
	Popup,
	ZoomControl,
	useMap,
} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';
import 'leaflet-fullscreen';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {
	Box,
	Text,
	Avatar,
	useBreakpointValue,
	IconButton,
} from '@chakra-ui/react';
import './trmapleaf.css';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';
import { CloseIcon } from '@chakra-ui/icons';

const DefaultIcon = L.icon({
	iconUrl: markerIcon,
	iconRetinaUrl: markerIcon2x,
	shadowUrl: markerShadow,
	iconSize: [25, 41],
	iconAnchor: [12, 41],
	popupAnchor: [1, -34],
	shadowSize: [41, 41],
});

L.Marker.prototype.options.icon = DefaultIcon;

interface Mark {
	latitude: number;
	longitude: number;
	title?: string;
	previewImage?: string;
}

interface MapComponentProps {
	defaultIndex?: number;
	marks?: Mark[];
	defaultFullscreen?: boolean;
	dismissMap?: () => void;
	fullscreen?: boolean;

	showCloseButton?: boolean;
}

const MapComponent: React.FC<MapComponentProps> = ({
	marks = [],
	defaultFullscreen,
	dismissMap,
	fullscreen = false,
	defaultIndex,
	showCloseButton,
}) => {
	const [currentMark, setCurrentMark] = useState(defaultIndex || 0);
	const [currentMarkForCard, setCurrentMarkForCard] = useState(currentMark);
	const [isFullscreen, setIsFullscreen] = useState(fullscreen);
	const isMobile = useBreakpointValue({ base: true, md: false });
	console.log('default index', defaultIndex);
	const markerRefs = useRef([]);

	useEffect(() => {
		if (markerRefs?.current[currentMark]) {
			// Open the popup for the current marker
			markerRefs?.current[currentMark].openPopup();
		}
	}, [currentMark]); // Re-run whenever `currentMark` changes

	useEffect(() => {
		setCurrentMarkForCard(currentMark);
	}, [currentMark]);
	const setFullScreenFunc = (value) => {
		setIsFullscreen(value);

		// Create a ref array for each carousel item
	};

	const FullscreenControl = memo(
		({
			setFullscreen,
			isFullScreen,
		}: {
			setFullscreen: (val: boolean) => void;
		}) => {
			const map = useMap();

			useEffect(() => {
				const onFullscreenChange = () => {
					// @ts-ignore
					const isFullscreen =
						document.fullscreenElement || map.isFullscreen?.();
					setFullscreen(!!isFullscreen);
					dismissMap();
				};

				if (
					!map._controlCorners.bottomright?.querySelector(
						'.leaflet-control-fullscreen',
					) &&
					!showCloseButton
				) {
					L.control
						.fullscreen({
							position: 'bottomright',
							title: 'Expand to fullscreen',
							titleCancel: 'Exit fullscreen',
						})
						.addTo(map);
				}

				map.on('fullscreenchange', onFullscreenChange);
				document.addEventListener('fullscreenchange', onFullscreenChange);

				return () => {
					map.off('fullscreenchange', onFullscreenChange);
					document.removeEventListener('fullscreenchange', onFullscreenChange);
				};
				// eslint-disable-next-line react-hooks/exhaustive-deps
			}, [map, setFullscreen]);

			return showCloseButton ? (
				<Box
					onClick={() => {
						dismissMap();
					}}
					position='absolute'
					top='20px'
					right='20px'
					zIndex='1000'>
					<IconButton aria-label='Dismiss map' icon={<CloseIcon />} />
				</Box>
			) : null;
		},
	);

	const CenterMapToMarker = memo(() => {
		const map = useMap();
		useEffect(() => {
			if (marks.length > 0 && marks[currentMark]) {
				const { latitude, longitude } = marks[currentMark];
				if (!isNaN(latitude) && !isNaN(longitude)) {
					map.setView([latitude, longitude], map.getZoom(), { animate: true });
				}
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [map, currentMark, marks]);
		return null;
	});

	const carouselRef = useRef(null);
	useEffect(() => {
		if (carouselRef.current) {
			console.log('Selected index:', carouselRef.current.state.selectedItem);
		}
	}, [currentMark]);

	useEffect(() => {
		if (marks && marks.length > 0) {
			const c = currentMark;
			setCurrentMark(-20);
			setTimeout(() => {
				setCurrentMark(c);
			}, 50);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [marks]);
	console.log('markks length', marks);
	return (
		<>
			{typeof currentMark === 'number' && (
				<MapContainer
					className='map-modal-map'
					id='map'
					center={
						marks && marks.length > 0
							? [marks[currentMark]?.latitude, marks[currentMark]?.longitude]
							: [0, 0]
					}
					zoom={50}
					style={{
						position: 'relative',
						height: '100%',
						width: '100%',
					}}
					zoomControl={false}>
					<TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />
					{marks.map((mark, index) => (
						<Marker
							ref={(el) => (markerRefs.current[index] = el)}
							key={index}
							position={[mark.latitude, mark.longitude]}>
							<Popup>{mark.title}</Popup>
						</Marker>
					))}

					<CenterMapToMarker />
					<ZoomControl position='topleft' />
					<FullscreenControl
						dismissMap={() => dismissMap}
						isFullScreen={isFullscreen}
						setFullscreen={setFullScreenFunc}
					/>

					{(defaultFullscreen || isFullscreen) && marks.length > 0 && (
						<Box
							height={{ base: '230px', md: 'unset' }}
							ml={{ base: '0', md: '10px' }}
							id='mark-cards'
							display='flex'
							borderRadius='20px 20px 0 0'
							flexDir='row'
							gap='20px'
							pl={'10px'}
							zIndex={9999}
							position='absolute'
							left={{ base: 0, lg: 10 }}
							bottom={{ base: '0', md: '100px' }}
							width='100%'
							overflow='hidden'
							bg={{ base: '#0B0A0B', md: 'transparent' }}>
							{!isNaN(currentMark) && (
								<Carousel
									ref={carouselRef}
									onClickThumb={(index) => {
										// console.log('index thumb ==>', index);
										setCurrentMark(index);
									}}
									onClickItem={async (index) => {
										clickThumbnail(index);
										await setCurrentMark(index);
										const currentCard = cardRefs.current[currentMark];
										// if (currentCard) {
										currentCard.scrollIntoView({
											behavior: 'smooth',
											block: 'center',
											inline: 'center',
										});
										// }
									}}
									onChange={(index) => {
										// setCurrentMark(index);
										// console.log('index==>', index);
										// console.log('current mark', currentMark);
									}}
									showThumbs={true}
									showIndicators={true}
									showStatus={false}
									selectedItem={currentMark}
									interval={1}
									// centerMode
									centerSlidePercentage={window.innerWidth < 768 ? 5 : 15}
									infiniteLoop={false}
									swipeable
									emulateTouch>
									{marks.map((mark, index) => (
										<Box
											key={index}
											onClick={(e) => {
												e.preventDefault();
												e.stopPropagation();
												// if (currentMark)
												setCurrentMark(index); // Set current mark to the clicked index
											}}
											display='flex'
											flexDir='row'
											bg={index === currentMark ? '#1B191B' : '#1B191B'}
											border={
												index === currentMarkForCard
													? '1px solid #9B5CFF'
													: '1px solid #fff'
											} // Border change on selection
											width={{ base: '160px', md: '150px', lg: '221px' }}
											height={{ base: '60px', md: '40px', lg: '60px' }}
											overflow='hidden'
											borderRadius='12px'
											cursor='pointer'>
											<Avatar
												w='30%'
												height='100%'
												borderRadius='0'
												src={mark.previewImage}
												name={mark.title}
											/>
											<Box
												p='3px'
												display='flex'
												justifyContent='center'
												alignItems='center'
												w='70%'
												height='100%'>
												<Text
													noOfLines={2}
													fontSize={isMobile ? '12px' : '12px'}
													color={'#fff'}>
													{mark.title}
												</Text>
											</Box>
										</Box>
									))}
								</Carousel>
							)}
						</Box>
					)}
				</MapContainer>
			)}
		</>
	);
};

export default memo(MapComponent);
