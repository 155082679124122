import React from 'react';
import {
	Avatar,
	Box,
	Flex,
	IconButton,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
} from '@chakra-ui/react';
import TraverseLogo from './TraverseLogo';
import TrCircleButton from './TrCircleButton';
import { pixels } from '../theme';
import TrSecondaryButton from './Buttons/TrSecondaryButton';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';

interface HeaderProps {
	isAuthenticated?: boolean;
	onOpenLogin?: () => void;
}

const Header: React.FC<HeaderProps> = ({ isAuthenticated, onOpenLogin }) => {
	const navigate = useNavigate();
	const RenderMenuItem = (props: any) => {
		return (
			<Box onClick={props?.onClick} px={pixels['30pixels']}>
				<MenuItem
					pl={0}
					height={pixels['50pixels']}
					background='transparent'
					fontSize={pixels['16pixels']}
					color={'rgba(255, 255, 255, 0.83)'}>
					{props.children}
				</MenuItem>
				{!props?.dontShowBorder && (
					<Box h='1px' w={'100%'} mx='auto' background='rgba(56, 55, 57, 1)' />
				)}
			</Box>
		);
	};
	return (
		<Box
			zIndex={51}
			backdropFilter='blur(10px)'
			bg='transparent'
			color='white'
			px={{ base: '5px', lg: '16px' }}
			height={'max-content'}
			pt='26px'>
			<Flex
				bg='transparent'
				color='white'
				fontWeight={'medium'}
				height={'lg_40'}
				marginTop='lg_40'
				alignItems='center'
				px={{ base: '10px', lg: '100px' }}
				justifyContent='space-between'>
				<TraverseLogo url='/home' />
				<Flex zIndex={3} gap={pixels['16pixels']}>
					{isAuthenticated ? (
						<Menu>
							<MenuButton
								as={IconButton}
								aria-label='Options'
								icon={
									<TrCircleButton aria-label={'My profile'}>
										<Avatar h='lg_46' w='lg_46' name='Dan Abrahmov' />
									</TrCircleButton>
								}
								variant=''
							/>
							<MenuList
								sx={{
									// pl: pixels['50pixels'],
									w: '378px',
									fontSize: pixels['16pixels'],
									background: 'rgba(40, 40, 40, 0.73)',
									border: '1px solid rgba(56, 55, 57, 1)',
								}}>
								<RenderMenuItem
									background='transparent'
									fontSize={pixels['16pixels']}
									color={'rgba(255, 255, 255, 0.83)'}
									onClick={() => {
										navigate('/home/profile')
									}}>
									My profile
								</RenderMenuItem>
								<RenderMenuItem
									onClick={() => {
										// localStorage.removeItem('token');
										// window.location.reload();
									}}
									color='trBlack'>
									Terms and conditions
								</RenderMenuItem>
								<RenderMenuItem
									onClick={() => {
										localStorage.removeItem('token');
										window.location.reload();
									}}
									color='trBlack'>
									About Us
								</RenderMenuItem>
								<RenderMenuItem
									dontShowBorder={true}
									onClick={() => {
										localStorage.removeItem('token');
										window.location.reload();
									}}
									color='trBlack'
									icon={<ExternalLinkIcon />}>
									Log Out
								</RenderMenuItem>
							</MenuList>
						</Menu>
					) : (
						<TrSecondaryButton width={'109px'} onClick={onOpenLogin}>
							Log in
						</TrSecondaryButton>
					)}
				</Flex>
			</Flex>
		</Box>
	);
};

export default Header;
