import { HStack, IconButton, Image } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const Header: React.FC = () => {
  const navigate = useNavigate();

  return (
    <HStack 
      justifyContent="space-between" 
      p={[4, 6, 8]}  // Responsive padding for mobile, tablet, and desktop
      spacing={[1, 2, 4]}  // Responsive spacing between HStack elements
    >
      <HStack>
        <IconButton
          aria-label="Go Back"
          icon={
            <Image 
              src="/icons/Back_Icon_White.svg" 
              alt="back"  
              w={["10px", "13px", "16px"]}  // Icon size adjustments for various screen sizes
            />
          }
          variant="ghost"
          onClick={() => { navigate(-1); }}
          bg="#FFFFFF08"
          size={["xs", "sm", "md"]}  // Adjust button size for different screens
          borderRadius="full"
        />
      </HStack>
      <HStack spacing={[0.5, 1, 2]}></HStack>
    </HStack>
  );
};

export default Header;
