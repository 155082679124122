import React from 'react';
import { Box } from '@chakra-ui/react';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { colors } from '../theme';

interface PhoneNumberInputProps {
	value: string;
	onChange: (value: string) => void;
	isDisable?: boolean;
	isInvalid?: boolean;
}

const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({
	value,
	onChange,
	isDisable,
	isInvalid,
}) => {
	return (
		<Box
			sx={{
				// Default styles for mobile view
				height: '44px',
				border: `1px solid ${isInvalid ? 'red' : "#FFFFFFA1"}`, // White for valid, red for invalid
				borderRadius: '8px',
				display: 'flex',
				py: '10px',
				alignItems: 'center',
				bg: 'white',
				width: '100%',
				'& .PhoneInput': { pl: '20px', border: 'none !important' },
				'& .PhoneInputInput': {
					border: 'none !important',
					height: '90%',
					flex: '1 1',
					ml: '20px',
					width: '100%',
					borderRadius: '50px',
					'&:focus': {
						outline: 'none',
						borderColor: 'transparent !important',
					},
				},
				// Media query for desktop view
				'@media (min-width: 769px)': { // Adjust the breakpoint as needed
					border: `1px solid ${isInvalid ? 'red' : colors.trGrey}`, // Grey for valid, red for invalid
					'& .PhoneInputInput': {
						color: 'inherit', // Default text color for desktop
					}
				},
			}}
		>
			<PhoneInput
				disabled={isDisable}
				style={{
					border: 'none',
					height: '44px',
				}}
				placeholder=''
				//@ts-ignore
				value={value}
				//@ts-ignore
				onChange={onChange}
				defaultCountry='IN'
				//@ts-ignore
				isInvalid={isInvalid}
			/>
		</Box>
	);
};

export default PhoneNumberInput;
