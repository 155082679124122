import { Box, Flex, Text, Image, useDisclosure } from '@chakra-ui/react';
import { pixels } from '../theme';
import { useContext } from 'react';
import { AppContext } from '../context/AppContext';
import ModalWithBackdrop from './ModalWithBackdrop';
import SigninComponent from './SigninComponent';
import { useNavigate } from 'react-router-dom';

const CityFlightDetail = ({ onOpenDetailsModal }: any) => {
	const { searchDetail, currentVideoIndex }: any = useContext(AppContext);
	const { isOpen, onClose } = useDisclosure();
	const navigate = useNavigate();

	return (
		<Box
			pl='18px'
			w={{ 'base': '100%', 'md': '300px', '2xl': '369px' }}
			mx='auto'>
			<ModalWithBackdrop
				isOpen={isOpen}
				onClose={() => {
					onClose();
					navigate('/home');
				}}>
				<SigninComponent onClose={onClose} />
			</ModalWithBackdrop>
			<Flex justifyContent={'space-between'}>
				{searchDetail &&
				searchDetail?.videos &&
				searchDetail?.videos.length > 0 ? (
					<Text
						textStyle='cardHeader'
						noOfLines={{ 'base': 2, '2xl': 2 }}
						sx={{
							lineHeight: '120%',
							fontSize: {
								'sm': pixels['24pixels'],
								'md': pixels['27pixels'],
								'lg': pixels['27pixels'],
								'2xl': pixels['34pixels'],
							},
						}}
						onClick={onOpenDetailsModal}
						cursor='pointer'>
						{typeof searchDetail?.user_query_entity?.query_entity ===
							'string' &&
						!Array.isArray(searchDetail?.user_query_entity?.query_entity)
							? searchDetail.user_query_entity.query_entity
							: ''}

						{/* {Array.isArray(searchDetail?.user_query_entity?.country)
							? `${searchDetail?.user_query_entity?.country.join(', ')} `
							: searchDetail?.user_query_entity?.country} */}
					</Text>
				) : (
					''
				)}
			</Flex>

			<Text display={{ base: 'none', lg: 'flex' }} textStyle='cardSubHeader'>
				<Image
					display={'inline'}
					mt='auto'
					mr={pixels['10pixels']}
					src='/icons/flight.svg'
					alt='flight'
				/>
				<Text mt={pixels['10pixels']}>
					flights starting at ₹{searchDetail?.flightStartingPrice}
				</Text>
			</Text>
			{searchDetail && searchDetail?.videos ? (
				<Text
					maxHeight={'90px'}
					width={'300px'}
					overflow={'auto'}
					// isTruncated
					textOverflow={'ellipsis'}
					noOfLines={2}
					display={{ base: 'none', lg: 'block' }}
					mt={pixels['20pixels']}
					fontSize={{
						'sm': '13px',
						'md': '13px',
						'lg': '13px',
						'2xl': '16px',
					}}
					textStyle={'cardDescription'}>
					{searchDetail?.videos[currentVideoIndex]?.description}
				</Text>
			) : (
				''
			)}
		</Box>
	);
};

export default CityFlightDetail;
