import {
  Input,
  InputGroup,
  InputRightElement,
  IconButton,
  Box,
  Image,
  Text,
  Flex,
  VStack,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { formatDateTime } from "../../utilities/formatDateTime";
import { ArrowRightIcon } from "@chakra-ui/icons";
import {
  insertIntoDatabase,
  fetchChatResponse,
} from "../../services/apiService";

interface InputFieldProps {
  placeholder: string;
}

const InputField: React.FC<InputFieldProps> = ({ placeholder }) => {
  const context: any = useContext(AppContext);
  const {
    onBehalf,
    setOnBehalf,
    chatEntries,
    setChatEntries,
    setIsLoading,
    dateTime,
    setDateTime,
    currChatId,
    setCurrChatId,
    previousChatLoading,
    setPreviousChatLoading,
    setTitle,
    setIsCollection,
  } = context;
  const navigation = useNavigate();
  const [inputValue, setInputValue] = useState("");
  const [format, setFormat] = useState<any>();

  useEffect(() => {
    console.log("useeffect 1");
    console.log("onBehalf is ", onBehalf);
    setIsCollection(false);

    if (!previousChatLoading) {
      const token = localStorage.getItem("token");
      console.log("conversationid is useeffect " + currChatId);

      // Generate a new chat ID if currChatId is empty

      // Fetch format from chatEntries
      if (chatEntries.length > 0) {
        const lastEntry = chatEntries[chatEntries.length - 1];
        if (lastEntry.message !== "...") {
          const entities =
            lastEntry.role === "assistant" && lastEntry.entities
              ? lastEntry.entities.map(
                (entity: {
                  image: any; title: any; content: any
                }) => ({
                  title: entity.title,
                  image:entity.image,
                  description: entity.content,
                })
              )
              : undefined;
          if (chatEntries.length === 1) setTitle(lastEntry.message);

          setFormat({
            token: token,
            conversation_id: currChatId,
            title: chatEntries.length === 1 ? lastEntry.message : undefined,
            imageUrl: chatEntries.length === 1 ? "" : undefined,
            content: {
              type: lastEntry.type,
              message: Array.isArray(lastEntry.message)
                ? lastEntry.message.join(" ").replace(/\s+/g, " ").trim()
                : lastEntry.message,
              role: lastEntry.role,
              time: lastEntry.time,
              entities: entities,
            },
          });
        }
      }
    }
    // eslint-disable-next-line
  }, [chatEntries]);

  //For the date & time
  useEffect(() => {
    console.log("useeffect 2 time");
    const intervalId = setInterval(() => {
      const now = new Date();
      setDateTime(formatDateTime(now));
    }, 100);
    return () => clearInterval(intervalId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log("useeffect 3");
    // Insert into database whenever format is updated
    const insertData = async () => {
      if (format) {
        setChatEntries((prevEntries: any[]) => {
          // Check if the last entry has the role "assistant"
          const lastEntry = prevEntries[prevEntries.length - 1];

          // Only set the new entry if the last entry is not an assistant
          if (!lastEntry || lastEntry.role !== "assistant") {
            return [
              ...prevEntries,
              {
                type: "TextR",
                role: "assistant",
                message: "...",
              },
            ];
          }

          // If the last entry is already an assistant, return the previous entries unchanged
          return prevEntries;
        });

        await insertIntoDatabase(format);
      }
    };
    insertData();
    // eslint-disable-next-line
  }, [format]); // Run this effect when format changes

  const handleSubmit = async () => {
    if (inputValue.trim() === "") return;
    if (currChatId === "") {
      const chatId =
        Date.now().toString(36) + Math.random().toString(36).substr(2);
      setCurrChatId(chatId);
    }
    if (placeholder === "Ask here...") {
      setChatEntries([]);
      const chatId =
        Date.now().toString(36) + Math.random().toString(36).substr(2);
      setCurrChatId(chatId);
      navigation("/home/chat");
    }
    // Prepare user's message
    // Update chat entries with the user's message
    setPreviousChatLoading(false);
    let query="";
    if (onBehalf?.title) {
      query=onBehalf.title;
      setChatEntries((prevEntries: any) => [
        ...prevEntries,
        {
          type: "Text",
          role: "user",
          message: inputValue,
          time: dateTime,
          onBehalf: onBehalf,
        },
      ]);
      setOnBehalf({});

    } else {
      setChatEntries((prevEntries: any) => [
        ...prevEntries,
        {
          type: "Text",
          role: "user",
          message: inputValue,
          time: dateTime,
        },
      ]);
    }

    // Clear input value
    setInputValue("");
    
    // Fetch chat response from the API
    if (currChatId === "") {
      const chatId =
        Date.now().toString(36) + Math.random().toString(36).substr(2);
        await setCurrChatId(chatId);
      }
      await fetchChatResponse(
      `${query}: ${inputValue}`,
      setChatEntries,
      dateTime,
      setIsLoading,
      chatEntries,
    );
  };

  return (
    <Box
      borderRadius={onBehalf?.title ? "12px 12px 12px 12px" : "99px"}
      boxShadow="lg"
      overflow="hidden"
      w={'full'}
      border="1px solid #16181B12"
    >
      {onBehalf?.title && (
        <Flex py={6} px={4} bg={"#F7F8F9"} gap={"5%"}>
          <Image
            src={onBehalf.imageUrl}
            maxH={"50px"}
            boxSize={"18%"}
            borderRadius={"20%"}
          />
          <VStack alignItems={"flex-start"} spacing={0}>
            <Text
              fontFamily="Poppins"
              fontSize="14px"
              fontWeight="700"
              lineHeight="20px"
              letterSpacing="-0.5px"
              textAlign="center"
              color={"#424242"}
            >
              {onBehalf.title}
            </Text>
            <Text
              fontFamily="Poppins"
              fontSize="13px"
              fontWeight="400"
              lineHeight="20px"
              letterSpacing="-0.5px"
              textAlign="center"
              color={"#424242"}
            >
              {onBehalf.subtitle}
            </Text>
          </VStack>
        </Flex>
      )}
      <InputGroup w={"100%"}>
        <Input
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder={placeholder}
          borderRadius={onBehalf?.title ? "0px 0px 12px 12px" : "99px"}
          bg="#FFFFFF"
          _placeholder={{ color: "#42424254" }}
          boxShadow={"0px 48px 100px 0px #110C2E26"}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSubmit();
              (e.target as HTMLInputElement).blur();
            }
          }}
        />
        <InputRightElement>
          {inputValue.trim() === "" ? (
            <IconButton
              aria-label="Mic button"
              icon={<img src="/icons/mic.svg" alt="mic" />}
              borderRadius="full"
              variant="ghost"
              _hover={{ bg: "gray.100" }}
              // onClick={() => {
              //   navigation("/home/VoiceAdvisor/");
              // }}
            />
          ) : (
            <IconButton
              aria-label="Send button"
              icon={<ArrowRightIcon />}
              borderRadius="full"
              variant="ghost"
              _hover={{ bg: "gray.100" }}
              onClick={handleSubmit}
            />
          )}
        </InputRightElement>
      </InputGroup>
    </Box>
  );
};

export default InputField;
