import { useState, useRef, useEffect, useCallback } from "react";
import { Box, Text, Link, Collapse, useOutsideClick, BoxProps } from "@chakra-ui/react";

interface CollapsibleTextProps extends BoxProps {
  content: string;
  maxLines?: number; // Max number of lines before showing "Read more"
}

const CollapsibleText: React.FC<CollapsibleTextProps> = ({ content, maxLines = 3, ...props }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [shouldShowToggle, setShouldShowToggle] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLDivElement>(null);

  const toggleExpand = () => setIsExpanded((prev) => !prev);

  // Function to check if the content exceeds the max number of lines
  const checkOverflow = useCallback(() => {
    if (textRef.current) {
      const lineHeight = parseInt(window.getComputedStyle(textRef.current).lineHeight, 10); // Get line height
      const singleLineHeight = lineHeight; // Height for a single line
      const maxHeight = lineHeight * maxLines; // Calculate max height for maxLines

      // Check if content overflows beyond one line and maxLines
      setShouldShowToggle(textRef.current.scrollHeight > singleLineHeight && textRef.current.scrollHeight > maxHeight);
    }
  }, [maxLines]);

  // Collapse the text when clicking outside the component
  useOutsideClick({
    ref: ref,
    handler: () => setIsExpanded(false),
  });

  useEffect(() => {
    checkOverflow();
  }, [content, checkOverflow]);

  return (
    <Box ref={ref} {...props}>
      <Collapse startingHeight={70} in={isExpanded}>
        <Text
          ref={textRef}
          sx={{
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
            WebkitLineClamp: isExpanded ? "none" : maxLines, // Limit lines when collapsed, show all when expanded
          }}
        >
          {content}
        </Text>
      </Collapse>
      {shouldShowToggle && (
        <Link fontSize={8} onClick={toggleExpand} display="block">
          {isExpanded ? "Show less" : "Read more"}
        </Link>
      )}
    </Box>
  );
};

export default CollapsibleText;
