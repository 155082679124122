import { Grid } from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../context/AppContext';
import SavedPlacesCard from './SavedPlacesCard';

const SavedPlaces = () => {
	const context = useContext(AppContext);
	const { bucketListCard, likeEntities } = context;
	console.log('entitiesssss===>', likeEntities);

	const [savedPlaces, setSavedPlaces] = useState([]);
	useEffect(() => {
		const tempSavedPlaces = [];
		for (let entity of likeEntities) {
			if (
				entity['country'] === bucketListCard[0] &&
				(entity['city'] === undefined ? 'Other' : entity['city']) ===
					bucketListCard[1]
			) {
				tempSavedPlaces.push(entity);
			}
		}
		setSavedPlaces(tempSavedPlaces);
	}, [likeEntities, bucketListCard]);

	return (
		<Grid templateColumns='repeat(2, 1fr)' gap={12}>
			{savedPlaces.map((place, index) => {
				return (
					<SavedPlacesCard
						index={index}
						previewImage={place.previewImage}
						title={place.title}
						description={place.description}
						vibe={place.tags}
						city={place.city}
					/>
				);
			})}
		</Grid>
	);
};

export default SavedPlaces;
