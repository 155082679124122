import React from 'react';
import { Card, Heading, HStack, VStack, Button, Image, Box } from "@chakra-ui/react";

interface ChatCardProps {
  imageSrc: string;
  altText: string;
  title: string;
  buttonText: string;
  onButtonClick?: () => void;
  onCardClick?: () => void;
}

const ChatCard: React.FC<ChatCardProps> = ({
  imageSrc,
  altText,
  title,
  buttonText,
  onButtonClick,
  onCardClick
}) => {
  return (
    <Card
      borderRadius="18px"
      p={0}
      w="250px"
      h="264px"
      border="1px solid #E5E5E5"
      onClick={onCardClick}
      overflow="hidden"
    >
      {imageSrc ? (
        <Image
          src={imageSrc}
          alt={altText}
          borderRadius="18px 18px 0 0"
          objectFit="cover"
          w="100%"
          h="70%" // Set a fixed height for the image
        />
      ) : (
        <Box
          borderRadius="18px 18px 0 0"
          bgColor="gray.200"
          w="100%"
          h="160px"
        />
      )}

      <HStack spacing="12px" mt={4} alignItems="flex-start" pb="20px" justifyContent="space-between" mx={2}>
        <VStack spacing={0} alignItems="flex-start" ml="6px">
          <Heading
            fontSize="16px" // Keep font size as specified
            fontWeight={700}
            color="#424242"
            noOfLines={2}
            maxW="180px" // Limit width to avoid overflow
          >
            {title || <Box w="100px" h="12px" bgColor="gray.200" borderRadius="4px" />}
          </Heading>
        </VStack>

        {buttonText ? (
          <Button
            variant="link"
            color="#7539D7"
            fontWeight={700}
            fontSize="12px"
            onClick={(e) => {
              e.stopPropagation(); // Prevents card click
              onCardClick?.();
            }}
          >
            {buttonText}
          </Button>
        ) : (
          <Box w="60px" h="12px" bgColor="gray.200" borderRadius="4px" />
        )}
      </HStack>
    </Card>
  );
};

export default ChatCard;
