import React, { useContext, useState, useRef, useCallback } from 'react';
import {
	Box,
	Image,
	useBreakpointValue,
	Progress,
	IconButton,
	Text,
} from '@chakra-ui/react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { FaPlay, FaPause } from 'react-icons/fa';
import VideoThumbnail from './VideoThumbnailViewer/Thumbnail';
import TrCircleButton from './TrCircleButton';
import { AppContext } from '../context/AppContext';
import ReactPlayer from 'react-player/lazy';
// import { pixels } from '../theme';
import { VideoSliderContext } from '../context/VideoSliderContext';

interface CustomButtonProps {
	onClick: () => void;
	label: string;
	position: 'left' | 'right';
}

const CustomButton: React.FC<CustomButtonProps> = ({
	onClick,
	label,
	position,
}) => (
	<TrCircleButton
		boxShadow={'none !important'}
		border='none'
		w='12.5px'
		top='50%'
		left={position === 'left' ? '5px' : undefined}
		right={position === 'right' ? '5px' : undefined}
		position='absolute'
		background='transparent'
		_hover={{ background: 'transparent' }}
		_active={{ background: 'transparent' }}
		zIndex={200}
		width='40px'
		height='40px'
		onClick={onClick}
		aria-label={label}>
		<Image
			width='20.5px'
			height='20.5px'
			src={
				position === 'left'
					? '/icons/left_anchor.svg'
					: '/icons/right_anchor.svg'
			}
		/>
	</TrCircleButton>
);

interface SliderProps {
	items: {
		previewImage: string;
		alt: string;
		video_url?: string;
		video_play_url: string;
	}[];
}

const ImageSlider: React.FC<SliderProps> = ({ items }) => {
	const { searchDetail, currentVideoIndex, setCurrentVideoIndex }: any =
		useContext(AppContext);
	const { playing, setPlaying }: any = useContext(VideoSliderContext);
	const isDesktop = useBreakpointValue({ base: false, lg: true });
	const [progress, setProgress] = useState<number>(0);
	const [videoStarted, setVideoStarted] = useState<boolean>(false);
	const playerRef = useRef<ReactPlayer>(null);
	const [isHovered, setIsHovered] = useState<boolean>(false);
	const [muted, setMuted] = useState(true);

	const handleSlideChange = (index: number) => {
		setCurrentVideoIndex(index);
		setMuted(false);
		setPlaying(true);
		setProgress(0);
	};

	const handleVideoPlayPause = (isPlaying: boolean) => {
		setPlaying(isPlaying);
		if (isPlaying) {
			setMuted(false);
		} else {
			setMuted(true);
		}
	};

	const handleProgressClick = (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
	) => {
		const boundingRect = event.currentTarget.getBoundingClientRect();
		const clickPosition = event.clientX - boundingRect.left;
		const clickPercentage = clickPosition / boundingRect.width;

		if (playerRef.current) {
			playerRef.current.seekTo(clickPercentage, 'fraction');
		}
	};

	const handlePlay = () => {
		setPlaying(true);
		setVideoStarted(true); // Mark video as started
		// setTimeout(() => {
		// 	setMuted(false);
		// }, 1000);
	};

	// Memoized renderThumbs function
	const renderThumbs = useCallback(() => {
		return items.map((slide, index) => (
			<VideoThumbnail
				thumbnail={
					slide.previewImage
						? slide.previewImage
						: //@ts-ignore
						  slide.video_play_url?.previewImage
				}
				key={index}
			/>
		));
	}, [items]); // Dependency on items to recreate when items change

	return (
		<Box
			minW={'100%'}
			minHeight={{ base: '100vh', lg: 'inherit' }}
			height={{ base: '100vh', lg: 'inherit' }}
			display={'flex'}
			position={'relative'}>
			{searchDetail && searchDetail?.videos ? (
				<Box
					fontSize={'10px'}
					p={'10px'}
					position='absolute'
					display={{ base: 'none', lg: 'flex' }}
					alignItems={'center'}
					justifyContent={'center'}
					bg='rgba(255, 255, 255, 1)'
					zIndex={20}
					left='25%'
					width={`calc(212.78px * 0.8)`}
					height='31.8px'
					// right={{ base: pixels['20pixels'], lg: `calc(20px * 0.8)` }}
					bottom={'150px'}
					// top={{ base: 'auto', lg: pixels['20pixels'] }}
				>
					<Text noOfLines={2}>
						📍{' '}
						{searchDetail?.videos[currentVideoIndex]?.title
							? searchDetail?.videos[currentVideoIndex]?.title
							: searchDetail?.videos[currentVideoIndex]?.video_play_url?.title}
					</Text>
				</Box>
			) : (
				''
			)}

			{/* Video and Carousel */}
			<Box
				borderRadius={'15px'}
				background={'#000'}
				position='relative'
				width='100%'
				height={{ base: '100%', lg: '600px' }}>
				<Carousel
					selectedItem={currentVideoIndex}
					onChange={handleSlideChange}
					className='tr-single-slider'
					showThumbs={isDesktop}
					autoPlay={false}
					showStatus={false}
					showIndicators={false}
					infiniteLoop={false}
					// className='tr-single-slider'
					renderThumbs={renderThumbs}
					renderArrowPrev={(onClickHandler, hasPrev, label) =>
						hasPrev && (
							<CustomButton
								onClick={() => {
									handleSlideChange(currentVideoIndex - 1);
									onClickHandler();
									setPlaying(false);
								}}
								label={label}
								position='left'
							/>
						)
					}
					renderArrowNext={(onClickHandler, hasNext, label) =>
						hasNext && (
							<CustomButton
								onClick={() => {
									handleSlideChange(currentVideoIndex + 1);
									onClickHandler();
									setPlaying(false);
								}}
								label={label}
								position='right'
							/>
						)
					}>
					{items && items.length > 0
						? items.map((slide, index) => (
								<Box
									className='video-preview'
									height={{ 'md': '420px', '2xl': '600px' }}
									w={{ 'lg': '250px', '2xl': '300px' }}
									minH={{ 'base': '3px', 'lg': `420px`, '2xl': '600px' }}
									maxH={{ 'base': '3px', 'lg': `420px`, '2xl': '600px' }}
									key={index}
									borderRadius={{ base: 0, lg: `calc(20px * 0.8)` }}
									overflow='hidden'
									bgImage={`url(${slide.previewImage})`}
									bgSize='cover'
									bgPosition='center'>
									{/* Empty Box for background image */}
								</Box>
						  ))
						: []}
				</Carousel>

				{/* Video Player */}
				{items && items.length > 0 && items[currentVideoIndex] && (
					<Box
						overflow={{ base: 'inherit', lg: 'hidden' }}
						borderRadius={'15px'}
						position='absolute'
						top={0}
						left={0}
						width='100%'
						height={{ base: '100%', lg: '600px' }}
						zIndex={10}
						onMouseEnter={() => setIsHovered(true)}
						onMouseLeave={() => setIsHovered(false)}>
						<ReactPlayer
							style={{ borderRadius: '15px' }}
							className='react-player'
							muted={muted}
							ref={playerRef}
							//@ts-ignore
							url={items[currentVideoIndex]?.video_play_url?.media_url}
							width='100%'
							height='100%'
							playing={playing}
							onPlay={handlePlay}
							onPause={() => handleVideoPlayPause(false)}
							onEnded={() => setMuted(true)}
							onStart={() => {
								setTimeout(() => {
									setMuted(false);
								}, 1000);
							}}
							onProgress={({ played }) => {
								if (playing) {
									setProgress(played * 100);
								}
							}}
							controls={false} // Hide default controls
							config={{
								file: {
									attributes: {
										style: {
											width: '100%',
											height: '100%',
											objectFit: 'cover',
										},
									},
								},
							}}
							fallback={
								<Image
									borderRadius={{ base: 0, lg: `calc(20px * 0.8)` }}
									src={items[currentVideoIndex].previewImage}
									alt={items[currentVideoIndex].alt}
									height={{ base: `calc(800px * 0.8)`, lg: 'auto' }}
									w={{ 'lg': `calc(300px * 0.8)`, '2xl': '300px' }}
									minH={{ 'lg': `calc(485px * 0.8)`, '2xl': '600px' }}
								/>
							}
						/>
						<Box
							display={{ base: 'block', lg: 'none' }}
							position='absolute'
							top={0}
							left={0}
							width='100%'
							height='100%'
							bg='rgba(0, 0, 0, 0.4)'
							zIndex={20}
						/>

						{!playing && videoStarted && (
							<IconButton
								className='play-button'
								icon={<FaPlay />}
								aria-label='Play'
								size='lg'
								bgColor={'transparent'}
								borderRadius={'50%'}
								position='absolute'
								top='50%'
								left='50%'
								transform='translate(-50%, -50%)'
								zIndex={60}
								onClick={() => handleVideoPlayPause(true)}
							/>
						)}
						{playing && isHovered && (
							<IconButton
								icon={<FaPause />}
								aria-label='Pause'
								size='lg'
								bgColor={'transparent'}
								borderRadius={'50%'}
								position='absolute'
								top='50%'
								left='50%'
								transform='translate(-50%, -50%)'
								zIndex={10}
								onClick={() => handleVideoPlayPause(false)}
							/>
						)}
						{playing && (
							<Progress
								colorScheme='pink'
								size='xs'
								value={progress}
								position='absolute'
								bottom='0'
								width='100%'
								onClick={handleProgressClick}
								cursor='pointer'
							/>
						)}

						{/* Play/Pause Button */}
						{playing && isHovered && (
							<IconButton
								icon={<FaPause />}
								aria-label='Pause'
								size='lg'
								bgColor='transparent'
								borderRadius='50%'
								position='absolute'
								top='50%'
								left='50%'
								transform='translate(-50%, -50%)'
								onClick={() => handleVideoPlayPause(false)}
								zIndex={20}
							/>
						)}
						{!playing && isHovered && (
							<IconButton
								icon={<FaPlay />}
								aria-label='Play'
								size='lg'
								bgColor='transparent'
								borderRadius='50%'
								position='absolute'
								top='50%'
								left='50%'
								transform='translate(-50%, -50%)'
								onClick={() => handleVideoPlayPause(true)}
								zIndex={20}
							/>
						)}

						{/* Progress Bar */}
						{playing && (
							<Progress
								colorScheme='pink'
								size='xs'
								value={progress}
								position='absolute'
								bottom='0'
								width='100%'
								cursor='pointer'
								onClick={(e) => {
									const boundingRect = e.currentTarget.getBoundingClientRect();
									const clickPosition = e.clientX - boundingRect.left;
									playerRef.current?.seekTo(
										clickPosition / boundingRect.width,
										'fraction',
									);
								}}
							/>
						)}
					</Box>
				)}
			</Box>
		</Box>
	);
};

export default ImageSlider;
