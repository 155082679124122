import { Box, Flex, useDisclosure, useMediaQuery } from '@chakra-ui/react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import useAuthRouteProtector from '../../hooks/useAuthRouteProtector';
import Header from '../Header';
import ModalWithBackdrop from '../ModalWithBackdrop';
import SigninComponent from '../SigninComponent';
import ShareSocialsModal from '../ShareSocialsModal';
import 'react-multi-carousel/lib/styles.css';
import { useEffect, useState } from 'react';

function NewAppLayout() {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { isAuthenticated, loading } = useAuthRouteProtector();
	const location = useLocation();
	const navigate = useNavigate();
	const [showHeader, setShowHeader] = useState(true);

	// Media query to check if the screen width is larger than 800px
	const [isLargerThan800] = useMediaQuery('(min-width: 800px)');

	useEffect(() => {
		// Hide header on mobile devices (screens smaller than 800px) and pages other than '/home'
		const isMobileAndNotHomePage =
			!isLargerThan800 && location.pathname !== '/home';
		setShowHeader(!isMobileAndNotHomePage);
	}, [location.pathname, isLargerThan800]);

	if (loading) {
		return <div>Loading...{isAuthenticated}</div>;
	}

	return (
		<Flex direction="column">
			<ModalWithBackdrop
				isOpen={isOpen}
				onClose={() => {
					onClose();
					navigate('/home');
				}}
			>
				<SigninComponent onClose={onClose} />
			</ModalWithBackdrop>
			<ShareSocialsModal />
			{/* Conditionally render Header based on screen size */}
			{isLargerThan800 && showHeader && (
				<Box zIndex="banner" position="fixed" w="100%" top={0}>
					<Header onOpenLogin={onOpen} isAuthenticated={isAuthenticated} />
				</Box>
			)}
			<Outlet />
		</Flex>
	);
}

export default NewAppLayout;
