import React, { useContext, useEffect } from 'react';
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	IconButton,
	Flex,
	useToast,
	useBreakpointValue,
} from '@chakra-ui/react';
import {
	FacebookIcon,
	TwitterIcon,
	LinkedinIcon,
	RedditIcon,
	EmailIcon,
	WhatsappIcon,
} from 'react-share';
import { AppContext } from '../context/AppContext';

const ShareModal = () => {
	const toast = useToast();
	const { shareModal, isShareModalOpen }: any = useContext(AppContext);
	// Utility function to check if the device is mobile
	const isMobile = useBreakpointValue({ base: true, md: false });

	useEffect(() => {
		if (
			navigator.share &&
			isMobile &&
			isShareModalOpen?.title &&
			isShareModalOpen?.url
		) {
			navigator
				.share({
					title: isShareModalOpen.title,
					text: isShareModalOpen.title,
					url: isShareModalOpen.url,
				})
				.catch(() => {});
		}
	}, [isMobile, isShareModalOpen]);

	// Function to handle sharing using navigator.share() on mobile
	const shareUsingNavigator = async (network: any) => {
		try {
			if (navigator.share && isMobile) {
				await navigator.share({
					title: isShareModalOpen.title,
					text: isShareModalOpen.title,
					url: isShareModalOpen.url,
				});
				toast({
					title: 'Shared successfully',
					status: 'success',
					duration: 500,
					isClosable: true,
				});
				shareModal.handleCloseModal();
			} else {
				toast({
					title:
						'Sharing is not supported on this browser. Using web sharing...',
					status: 'warning',
					duration: 3000,
					isClosable: true,
				});

				switch (network) {
					case 'Facebook':
						window.open(
							`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
								isShareModalOpen?.url,
							)}`,
							'_blank',
							'width=600,height=400',
						);
						break;
					case 'Twitter':
						window.open(
							`https://twitter.com/intent/tweet?url=${encodeURIComponent(
								isShareModalOpen?.url,
							)}&text=${encodeURIComponent(isShareModalOpen?.title)}`,
							'_blank',
						);
						break;
					case 'LinkedIn':
						window.open(
							`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
								isShareModalOpen?.url,
							)}&title=${encodeURIComponent(isShareModalOpen?.title)}`,
							'_blank',
						);
						break;
					case 'Reddit':
						window.open(
							`https://www.reddit.com/submit?url=${encodeURIComponent(
								isShareModalOpen?.url,
							)}&title=${encodeURIComponent(isShareModalOpen?.title)}`,
							'_blank',
						);
						break;
					case 'Email':
						window.location.href = `mailto:?subject=${encodeURIComponent(
							isShareModalOpen?.title,
						)}&body=${encodeURIComponent(
							`Check out this link: ${isShareModalOpen?.url}`,
						)}`;
						break;
					case 'WhatsApp':
						window.open(
							`https://api.whatsapp.com/send?text=${encodeURIComponent(
								`${isShareModalOpen?.title}: ${isShareModalOpen?.url}`,
							)}`,
							'_blank',
						);
						break;
					default:
						break;
				}
				shareModal.handleCloseModal();
			}
		} catch (error: any) {
			shareModal.handleCloseModal();
			// toast({
			// 	title: 'Share cancelled or failed',
			// 	description: error?.message,
			// 	status: 'error',
			// 	duration: 3000,
			// 	isClosable: true,
			// });
		}
	};

	// Share buttons using react-share for desktop
	const shareButtons = [
		{ icon: FacebookIcon, network: 'Facebook' },
		{ icon: TwitterIcon, network: 'Twitter' },
		{ icon: LinkedinIcon, network: 'LinkedIn' },
		{ icon: RedditIcon, network: 'Reddit' },
		{ icon: EmailIcon, network: 'Email' },
		{ icon: WhatsappIcon, network: 'WhatsApp' },
	];

	return (
		<Modal
			isOpen={isShareModalOpen?.title && !isMobile}
			onClose={shareModal.handleCloseModal}
			size='sm'
			isCentered>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Share This Post</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Flex justifyContent='space-around'>
						{shareButtons.map(({ icon: Icon, network }) => (
							<IconButton
								key={network}
								as={Icon}
								onClick={() => {
									shareUsingNavigator(network);
								}}
								aria-label={`Share on ${network}`}
								variant='ghost'
								size='lg'
							/>
						))}
					</Flex>
				</ModalBody>
				<ModalFooter>
					{/* Add additional buttons or text in the modal footer if needed */}
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default ShareModal;
