import { Box } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

const IframePage = () => {
	const { origin, destination } = useParams();

	useEffect(() => {
		// Remove any existing script with the same ID if re-rendered
		const existingScript = document.getElementById('tp-script');
		if (existingScript) {
			existingScript.remove();
		}

		// Create a new script element
		const script = document.createElement('script');
		script.id = 'tp-script';
		script.async = true;

		// Specify the target container using the `container` parameter
		script.src = `https://tp.media/content?trs=353925&shmarker=575775&locale=en&curr=INR&default_origin=${origin}&default_destination=${destination}&powered_by=true&border_radius=0&plain=false&color_button=%239B5CFF&color_button_text=%23ffffff&color_border=%239B5CFF&promo_id=4132&campaign_id=121`;
		script.charset = 'utf-8';

		// Append the script to the container
		document.getElementById('hello')?.appendChild(script);

		// Function to simulate a click on the Search button
		const clickSearchButton = () => {
			try {
				// Access iframe or widget and click the button (if available)
				const iframe = document.querySelector('iframe');
				if (iframe && iframe.contentWindow) {
					// Access the inner document of the iframe
					const iframeDocument = iframe.contentWindow.document;
					const searchButton = iframeDocument.querySelector('.form-submit'); // Replace with the actual class or ID of the search button
					console.log('search button', searchButton);
					if (searchButton) {
						console.log('Search button found, attempting click.');
						//@ts-ignore
						searchButton.click(); // Simulate the click
					} else {
						console.warn('Search button not found, retrying...');
						setTimeout(clickSearchButton, 5000); // Retry after a short delay
					}
				}
			} catch (error) {
				// Catch errors caused by cross-origin access restrictions
				console.error(
					'Failed to access iframe content due to cross-origin restrictions',
					error,
				);
			}
		};

		// Trigger the click after a delay to give the widget time to load
		setTimeout(clickSearchButton, 9000);

		// Cleanup script when component unmounts
		return () => {
			document.getElementById('hello')?.removeChild(script);
		};
	}, [origin, destination]);

	return (
		<Box p='100px' id='hello' style={{ minHeight: '800px' }}>
			{/* The widget will render inside this div */}
			<div id='tp-widget-container'></div>
		</Box>
	);
};

export default IframePage;
