import React, { useState, useEffect, useContext } from 'react';
import { Box, Text, Flex, Image, Button, Heading, VStack, HStack, Spinner, Skeleton } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import LocationButton from './LocationButton';
import ExploreButton from './ExploreButton';
import { AppContext } from '../../context/AppContext'; // Make sure this path is correct

type LocationKey = 'sanur' | 'pemuteran' | 'nusaPenida' | 'nusaLembongan';

type Location = {
  videoSrc: string;
  title: string;
  description: string;
};

// Simulating an API call to fetch locations
const fetchLocations = (): Promise<Record<LocationKey, Location>> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        sanur: {
          videoSrc: '/videos/sanur.mp4',
          title: 'Sanur',
          description: 'Explore the stunning white sand beaches and more',
        },
        pemuteran: {
          videoSrc: '/videos/pemuteran.mp4',
          title: 'Pemuteran',
          description: 'A paradise for water adventure seekers and explorers',
        },
        nusaPenida: {
          videoSrc: '/videos/nusa-penida.mp4',
          title: 'Nusa Penida',
          description: 'A Playground for Underwater Explorers',
        },
        nusaLembongan: {
          videoSrc: '/videos/nusa-lembongan.mp4',
          title: 'Nusa Lembongan',
          description: 'Relax in the tranquility of Nusa Lembongan’s beaches',
        },
      });
    }, 0); // Simulated delay
  });
};

const TravelAppUI: React.FC = () => {
  const navigate = useNavigate();
  const [currentLocation, setCurrentLocation] = useState<Location | null>(null);
  const [locations, setLocations] = useState<Record<LocationKey, Location> | null>(null);
  const [selectedLocationKey, setSelectedLocationKey] = useState<LocationKey>('sanur');
  const [loading, setLoading] = useState<boolean>(true);
  const context: any = useContext(AppContext);
  const { setStoryList, setStories } = context;

  useEffect(() => {
    // Fetch location data on mount
    fetchLocations().then((data) => {
      setLocations(data);
      setCurrentLocation(data.sanur); // Set default location
      setLoading(false);
    });
  }, []);

  const handleLocationChange = (locationKey: LocationKey) => {
    if (locations) {
      setCurrentLocation(locations[locationKey]);
      setSelectedLocationKey(locationKey);
    }
  };

  const handleExploreButton = (title: any) => {
    setStoryList([
      {
        id: 1,
        title: "Sanur",
        videoUrl: [
          "/videos/sanur.mp4",
          "/videos/sanur.mp4",

        ],
        subtitle: "Bali, Indonasia",
        details: "Explore the stunning white sand beaches and more"
      },
      {
        id: 2,
        title: "Pemuteran",
        videoUrl: [
          "/videos/pemuteran.mp4",
          "/videos/pemuteran.mp4",
        ],
        subtitle: "Bali, Indonasia",
        details: "A paradise for water adventure seekers and explorers"
      },
      {
        id: 3,
        title: "Nusa Penida",
        videoUrl: [
          "/videos/nusa-penida.mp4",
          "/videos/nusa-penida2.mp4",
        ],
        subtitle: "Bali, Indonasia",
        details: "Nusa Penida, one of Bali's beautiful islands..."
      },
    ]);
    setStories(title);
    // navigate("/home/CollectionStories")
  }

  if (loading || !currentLocation) {
    return (
      <Flex h="100vh" justifyContent="center" alignItems="center" bg="blackAlpha.900" >
        <Spinner size="xl" color="white" />
      </Flex>
    );
  }

  return (
    <Box>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}

        transition={{ duration: 0.6, ease: 'easeInOut' }}
      >
        {/* Video Background */}
        <Box position="absolute" top="0" left="0" width="100%" height="100%" zIndex={1}>
          <Box
            position="fixed"
            top="0"
            left="0"
            width="100vw"
            height="50vh"
            bg="linear-gradient(180deg, #111417 0%, rgba(7, 9, 11, 0) 100%)"
          />
          <Box
            position="fixed"
            bottom="0"
            left="0"
            width="100vw"
            height="45vh"
            bg="linear-gradient(0deg, #111417 7.37%, rgba(7, 9, 11, 0) 100%)"
          />
          <video
            autoPlay
            muted
            loop
            playsInline
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            key={currentLocation.videoSrc}
          >
            <source src={currentLocation.videoSrc} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Box>

        {/* Back Button */}
        <Button bg="inherit" variant="link" onClick={() => navigate('/home')} zIndex={2} p="20px">
          <Image src="/icons/Back_Icon_White.svg" alt="back" fallback={<Skeleton height="100%" width="100%" />} />
        </Button>

        {/* Main Content */}
        <Flex zIndex={2} position="relative" h="90vh" justifyContent="space-between" direction="column">
          <VStack spacing={4}>
            <Heading fontFamily="Poppins" fontSize="50px" fontWeight="600" textAlign="center" color="#FFFFFFED">
              {currentLocation.title}
            </Heading>
            <Text fontSize="14px" fontWeight="400" lineHeight="1.5" textAlign="center" color="#FFFFFFFC" maxW="287px">
              {currentLocation.description}
            </Text>
          </VStack>

          {/* Explore Button */}
          <Box position={'absolute'} alignSelf={'center'} bottom={'25vh'}>
            <ExploreButton handleNextLocation={() => handleExploreButton(currentLocation.title)} />
          </Box>

          {/* Location Buttons */}
          <HStack spacing={4} wrap="wrap" justify="center" position={'fixed'} bottom={'2vh'} left="11%">
            {locations && Object.keys(locations).map((key) => (
              <LocationButton
                key={key}
                location={locations[key as LocationKey]}
                isSelected={key === selectedLocationKey}
                onClick={() => handleLocationChange(key as LocationKey)}
              />
            ))}
          </HStack>
        </Flex>
      </motion.div>
    </Box>
  );
};

export default TravelAppUI;
