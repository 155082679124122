import { Input, InputGroup, InputRightElement, Image } from "@chakra-ui/react";
import React, { useState } from "react";

interface SearchBarProps {
  onSearch: (searchTerm: string) => void;
}

const SearchBar: React.FC<SearchBarProps> = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value); // Update the state with the input value
  };

  const handleSearch = () => {
    if (searchTerm.trim()) {
      onSearch(searchTerm); // Trigger the search when Enter is pressed or icon is clicked
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <InputGroup my={4} w={'92vw'}>
      <Input
        py={'25px'}
        placeholder="Search"
        _placeholder={{ color: "#FFFFFF" }}
        bg="#242127"
        color="#FFFFFF"
        borderRadius="12px"
        border={'1px solid #FFFFFF12'}
        h={'48px'}
        pl={5}
        value={searchTerm} // Controlled input
        onChange={handleInputChange}
        onKeyDown={handleKeyDown} // Trigger search on Enter key press
      />
      <InputRightElement
        py={'25px'}
        mt={'1px'}
        children={
          <Image
            boxSize={'18px'}
            src={'/icons/search2.svg'}
            alt="search"
            cursor="pointer"
            onClick={handleSearch} // Trigger search on icon click
          />
        }
      />
    </InputGroup>
  );
};

export default SearchBar;
