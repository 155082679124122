import React from "react";
import MoreFromInfluecerCard from "./MoreFromInfluecerCard";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Box, Container, SimpleGrid } from "@chakra-ui/react";
import { pixels } from "../theme";
import { useContext } from "react";
import { AppContext } from "../context/AppContext";

const CustomLeftArrow = ({ onClick }) => {
  const style = {
    position: "absolute",
    cursor: "pointer",
    zIndex: "1",
    left: "-5px",
    top: "85px",
  };
  return (
    <button onClick={onClick} style={style} className="custom-left-arrow">
      <img src="/icons/left.svg" alt="SVG Button" width="50" height="50" />
    </button>
  );
};

const CustomRightArrow = ({ onClick }) => {
  const style = {
    position: "absolute",
    cursor: "pointer",
    zIndex: "1",
    right: "-5px",
    top: "85px",
  };
  return (
    <button
      onClick={onClick}
      className="custom-right-arrow"
      position="absolute"
      style={style}
    >
      <img src="/icons/right.svg" alt="SVG Button" width="50" height="50" />
    </button>
  );
};

const CardSlider = (props) => {
  const { setCurrentVideoIndex } = useContext(AppContext);
	const { searchDetail }= useContext(AppContext);


  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 9,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1160 },
      items: 6,
    },

    tablet: {
      breakpoint: { max: 1160, min: 464 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };


  return (
    <>
      {/* desktop */}

      <Box display={{ base: "none", lg: "block", md: "block" }}>
        {searchDetail && searchDetail?.videos && searchDetail?.videos.length > 0 ? 
        <Carousel
          color="white"
          responsive={responsive}
          customLeftArrow={<CustomLeftArrow />}
          customRightArrow={<CustomRightArrow />}
          containerClass="carousel-container influencer-card-carousel"
          itemClass="carousel-item"
          autoPlay
          rewind={true}
        >
          {searchDetail?.videos.map((value, index) => {
            return (
              <React.Fragment key={index}>
                <MoreFromInfluecerCard
                  key={index}
                  index={index}
                  placeName={value["title"]}
                  imageUrl={value["previewImage"]}
                  videoUrl={value["url"]}
                  boxSize={"200px"}
                  width={"160px"}
                />
              </React.Fragment>
            )
          })}
        </Carousel>: ''}
      </Box>
      {/* mobile */}
      <Box display={{ base: "block", lg: "none", md: "none" }}>
        <Container p={"0px"} m={"0px"}>
          <SimpleGrid gap={pixels["20pixels"]} columns={2} spacing={5}>
            {props.videos ? props?.videos

              .map((value, index) => {
                console.log("value", value)
                return (
                  <React.Fragment key={index}>
                    <MoreFromInfluecerCard
                      onClose={() => {
                        setCurrentVideoIndex(index)
                        props?.onClose()
                      }}
                      index={index}
                      key={index}
                      placeName={value["title"]}
                      imageUrl={value?.video_play_url?.previewImage}
                      videoUrl={value["url"]}
                      boxSize={"150px"}
                      width={"100%"}
                    />
                  </React.Fragment>
                );
              }): ''}
          </SimpleGrid>

        </Container>
      </Box>
    </>
  );
};

export default CardSlider;
